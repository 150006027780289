import React from "react";

import { FiPhoneCall, FiSend } from "react-icons/fi";
import { BsWhatsapp } from "react-icons/bs";
import { HiOutlineLocationMarker } from "react-icons/hi";

function Info() {
  return (
    <div className="px-5 text-center md:px-0 pb-10 md:pb-0 max-w-4xl mx-auto grid gap-10 grid-cols-2 md:grid-cols-4 my-12">
      {/* CALL */}
      <a href="tel:0553333409">
        {" "}
        <div className="contact-card">
          <div className="">
            <FiPhoneCall className="inline mb-4" size={30} />
          </div>
          <div>
            <h3 className="font-semibold text-lg">Call us</h3>
            <p className="font-medium text-sm md:text-sm  md:mb-1 mb-2 text-gray">
              <a href="tel:0553333409">055 3333 409</a>
            </p>
          </div>
        </div>
      </a>

      {/* WHATSAPP */}
      <a
        target="_blank"
        rel="noreferrer"
        href="https://wa.me/233554314241?text=Welcome%20to%20Vago,%0AHow%20may%20we%20help%20you"
      >
        <div className="contact-card">
          <div>
            <BsWhatsapp className="inline mb-4" size={30} />
          </div>
          <div>
            <h3 className="font-semibold text-lg">WhatsApp</h3>
            <p className="font-medium text-sm md:text-sm  md:mb-1 mb-2 text-gray">
              Tap to send message
            </p>
          </div>
        </div>
      </a>

      {/* EMAIL */}
      <a href="mailto:info@vanetworks.net">
        {" "}
        <div className="contact-card">
          <div>
            <FiSend className="inline mb-4" size={30} />
          </div>
          <div>
            <h3 className="font-semibold text-lg">Send an Email</h3>
            <p className="font-medium text-sm md:text-sm  md:mb-1 mb-2 text-gray">
              info@vanetworks.net
            </p>
          </div>
        </div>
      </a>

      {/* VISIT US */}
      <div className="contact-card">
        <div>
          <HiOutlineLocationMarker className="inline mb-4" size={30} />
        </div>
        <div>
          <h3 className="font-semibold text-lg">Locate Us</h3>
          <p className="font-medium text-sm md:text-sm text-gray">
            Mon - Fri (8am to 5pm)
          </p>
          <p className="font-medium text-sm md:text-sm text-gray">
            Vanguard House, Independent Avenue
          </p>
          <p className="font-medium text-sm md:text-sm text-gray">
            Ridge-Accra
          </p>
        </div>
      </div>
    </div>
  );
}

export default Info;

// <a href="wa.me/message">
//   {" "}
//   <div className="flex flex-col  md:items-center md:flex-row gap-4">
//     <div className="p-6 w-24 h-24  border border-primary rounded-xl flex items-center justify-center">
//       <BsWhatsapp className="inline" size={30} />
//     </div>
//     <div>
//       <h3 className="font-semibold text-lg">WhatsApp</h3>
//       <p className="font-medium text-sm md:text-sm  md:mb-1 mb-2 text-gray">
//         Tap to send message
//       </p>
//     </div>
//   </div>
// </a>;
