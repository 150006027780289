import React from "react";

import logo from "../../assets/logos/logo-dark.png";

import { Twitter, Instagram, Linkedin, Facebook } from "react-feather";
import { NavLink } from "react-router-dom";

function Footer() {
  return (
    <footer className="footer">
      <div className="flex flex-col md:flex-row justify-between">
        {/* About */}
        <div className="basis-1/4 mx-5">
          <h3 className="footer-header mb-4">
            <img className="w-40" src={logo} alt="logo" />
          </h3>
          <p className="mb-0">
            A significant Pan African digital Financial Services distributor in
            Africa
          </p>
          <div className="flex flex-row mt-1 md:mt-5 mb-10 md:mb-0">
            <a
              className="mr-2"
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com"
            >
              <Twitter className="w-4" />
            </a>
            <a
              className="mr-2"
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com"
            >
              <Facebook className="w-4" />
            </a>
            <a
              className="mr-2"
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com"
            >
              <Instagram className="w-4" />
            </a>
            <a
              className="mr-2"
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/company/va-network-agency/"
            >
              <Linkedin className="w-4" />
            </a>
          </div>
        </div>

        {/* company */}
        <div className="basis-1/4 mx-5">
          <h3 className="footer-header">Products</h3>
          <ul className="footer-links">
            <li>
              <NavLink to="/product/taxigo"> Taxigo</NavLink>
            </li>
            <li>
              {" "}
              <NavLink to="/product/vago">VAGO</NavLink>
            </li>
          </ul>
        </div>

        {/* Resources */}
        <div className="basis-1/4 mx-5">
          <h3 className="footer-header">Company</h3>
          <ul className="footer-links">
            <li>
              <NavLink to="/about">About Us</NavLink>
            </li>
            {/* <li>Team</li> */}
          </ul>
        </div>

        {/* Locate Us*/}
        <div className="basis-1/4 mx-5 mb-10 md:mb-0">
          <h3 className="footer-header"> Locate Us</h3>
          <p>
            Vanguard House, <br /> Independent Avenue <br /> Ridge-Accra <br />{" "}
            GA-052-4942
          </p>
        </div>

        {/* Working Hours */}
        <div className="basis-1/4 mx-5">
          <h3 className="footer-header"> Working Hours</h3>
          <p>
            Mondays - Fridays <br /> 8am - 5pm <br />
          </p>
        </div>
      </div>

      <hr className="my-10 mx-10 text-center" />

      <div className="mx-5 text-sm flex mt-5 pb-3 flex-row justify-between">
        <p>© 2023 VA Networks</p>
        <p>All Rights Reserved</p>
      </div>
    </footer>
  );
}

export default Footer;
