import React, { useState } from "react";

import { deleteProduct } from "../../../firebase";
import Notify from "../../../components/notifications/Notify";

import { useNavigate } from "react-router-dom";

function DeleteProduct({ product, setDeleteModal }) {
  const [loading, setLoading] = useState(false);
   const navigate = useNavigate();

  const del = () => {
    setLoading(true);
    Notify("info", "Deleting, please wait");

    deleteProduct(product.name.toLowerCase())
      .then(() => {
        setDeleteModal(false);
        setLoading(false);
        Notify("success", "Deleted successfully");
       navigate("/products");
      })
      .catch((e) => {
        setDeleteModal(false);
        setLoading(false);
        Notify("error", "Error occured");
      });
  };
  return (
    <div className="text-center">
      <p className="font-normal mx-auto text-lg w-96 mb-10">
        This action is irreversible and the data cannot be recovered ever
      </p>

      <button disabled={loading} onClick={() => del()} className="btn-danger">
        I understand, delete {product.name}
      </button>
    </div>
  );
}

export default DeleteProduct;
