import React, { useState, useContext } from "react";

import Modal from "../../../components/modal/Modal";

import { AiOutlineEdit } from "react-icons/ai";
import { createLog, updateFaqsProduct } from "../../../firebase";
import { IoIosCloseCircleOutline } from "react-icons/io";

import { AuthContext } from "../../../contexts/AuthContext";

import { produce } from "immer";

// notify
import Notify from "../../../components/notifications/Notify";

function FaqsUpdate({ product, change, setChange }) {
  const { user } = useContext(AuthContext);

  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // faqs
  const [faqs, setFaqs] = useState(product.faqs);

  // handle update
  const handleUpdate = () => {
    let id = product.name.toLowerCase();
    setLoading(true);
    updateFaqsProduct(id, faqs)
      .then(() => {
        let data = {
          type: "product",
          action: "Update",
          rep: user[0].name,
          repEmail: user[0].email,
          details: "Updated " + product.name + "'s FAQs",
        };
        createLog(data)
          .then(() => {
            setIsOpen(false);
            setChange(!change);
            Notify("success", "Updated!");
          })
          .catch((e) => {
            setLoading(false);
            Notify("error", "Error occured");
          });
      })
      .catch((e) => {
        setIsOpen(false);
        Notify("error", "Error occured");
      });
  };

  const deleteFaqInput = (faqID) => {
    const allFaqs = [...faqs];
    const newFaqs = allFaqs.filter((item) => item.id !== faqID);
    setFaqs(newFaqs);
  };

  return (
    <div className="inline">
      <AiOutlineEdit
        onClick={() => setIsOpen(true)}
        size={14}
        className="ml-5 inline w-8 h-8 bg-blue-200 hover:bg-blue-400 rounded-full p-2 cursor-pointer"
      />

      {/* update function */}
      <Modal title="FAQs" isOpen={isOpen} setIsOpen={setIsOpen}>
        <div>
          <div className="my-2">
            {faqs.map((single, i) => {
              return (
                <div className="flex gap-10 items-center my-5">
                  <div className="flex-1">
                    <input
                      type="text"
                      className="admin-input"
                      value={single.question}
                      placeholder="Question"
                      onChange={(e) => {
                        const question = e.target.value;
                        setFaqs((currentField) =>
                          produce(currentField, (value) => {
                            value[i].question = question;
                          })
                        );
                      }}
                    />
                    <textarea
                      rows={3}
                      type="text"
                      className="admin-input"
                      value={single.answer}
                      placeholder="Answer"
                      onChange={(e) => {
                        const answer = e.target.value;
                        setFaqs((currentField) =>
                          produce(currentField, (value) => {
                            value[i].answer = answer;
                          })
                        );
                      }}
                    />
                  </div>

                  {faqs.length > 1 && (
                    <IoIosCloseCircleOutline
                      className="hover:text-red-600 font-normal cursor-pointer"
                      onClick={() => deleteFaqInput(single.id)}
                    />
                  )}
                </div>
              );
            })}
          </div>
          <button
            onClick={() => {
              setFaqs((faqs) => [
                ...faqs,
                {
                  id: Math.floor(Math.random() * 90 + 10),
                  question: "",
                  answer: "",
                },
              ]);
            }}
            className="btn-primary-admin-outline"
          >
            Add FAQ +{" "}
          </button>
        </div>
        <button
          disabled={loading}
          onClick={() => handleUpdate()}
          className="btn-primary-admin my-5"
        >
          Update
        </button>
      </Modal>
    </div>
  );
}

export default FaqsUpdate;
