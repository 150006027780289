import React from "react";

import { BsArrowDownCircle } from "react-icons/bs";

import { Disclosure } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function FAQS({ product }) {
  return (
    <div className="mt-20 p-20 bg-[#F8F7FC]">
      {" "}
      <div className="w-full mx-5 md:max-w-3xl  md:mx-auto">
        <h3 className="text-2xl font-bold text-primary">FAQs</h3>

        <div className="my-10">
          {product.faqs.map((single) => {
            return (
              <Disclosure
                as="div"
                defaultOpen={single.position === 1}
                key={single.question}
                className=""
              >
                {({ open }) => (
                  <>
                    <dt className="text-lg my-6 rounded-lg pb-2 text-primary">
                      <Disclosure.Button className="flex w-full items-start justify-between text-left">
                        <span className="text-primary font-semibold">
                          {single.question}
                        </span>
                        <hr />
                        <span className="flex h-7 w-7 items-center">
                          <BsArrowDownCircle
                            className={classNames(
                              open ? "-rotate-180" : "rotate-0",
                              "h-6 w-6 transform"
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-1">
                      <p className="text-base font-normal text-primary max-w-2xl">
                        {single.answer}
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default FAQS;
