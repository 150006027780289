import React from "react";

import Layout from "../../components/layout/Layout";
import Border from "../../components/border/Border";
import AllProducts from "./components/AllProducts";

import { NavLink } from "react-router-dom";

function Products() {
  return (
    <Layout>
      <div className="flex justify-between items-center">
        <h3>Products</h3>
        <NavLink to="/manage/product/add">
          <button className="btn-primary text-sm font-normal">
            Add Product
          </button>
        </NavLink>
      </div>
      <Border />

      <AllProducts />
    </Layout>
  );
}

export default Products;
