import React, { useState, useEffect } from "react";

import { getAllUsers } from "../../../firebase";

import Modal from "../../../components/modal/Modal";

import capitalize from "../../../utils/Capitalize";

import Notify from "../../../components/notifications/Notify";

import dayjs from "dayjs";
import Loader from "../../../components/loader/Loader";

import { AiOutlineEdit } from "react-icons/ai";
import { BsTrash } from "react-icons/bs";
import Edit from "./Edit";
import Delete from "./Delete";

// dayjs localizes
var localizedFormat = require("dayjs/plugin/localizedFormat");

function CurrentUsers() {
  dayjs.extend(localizedFormat);

  // state
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const [showEdit, setShowEdit] = useState(false);
  const [showDel, setShowDel] = useState(false)
  const [selectedUser, setSelectedUser] = useState({ name: "" });

  const [change, setChange] = useState(false);

  // get user
  useEffect(() => {
    setLoading(true);
    getAllUsers()
      .then((snapshot) => {
        let all = [];
        snapshot.docs.forEach((doc) => {
          all.push({ ...doc.data(), id: doc.id });
        });
        setUsers(all);
        setLoading(false);
      })
      .catch((e) => {
        Notify("error", "Error fetching users");
      });
  }, [change]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <table class="w-full font-normal text-sm text-left text-gray-700">
          <thead class="text-xs text-gray-700 uppercase bg-gray-100 ">
            <tr>
              <th scope="col" class="px-6 py-3">
                Created At
              </th>
              <th scope="col" class="px-6 py-3">
                Name
              </th>
              <th scope="col" class="px-6 py-3">
                Email
              </th>
              <th scope="col" class="px-6 py-3">
                Type
              </th>
              <th scope="col" class="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {!loading &&
              users.map((user, index) => {
                return (
                  <tr class="bg-white border-b py-5">
                    <td class="px-6 py-4">
                      {dayjs(new Date(user.createdAt.seconds * 1000)).format(
                        "ll"
                      )}
                    </td>
                    <td class="px-6 py-4">{user.name}</td>
                    <td class="px-6 py-4">{user.email}</td>
                    <td class="px-6 py-4">{capitalize(user.userType)}</td>
                    <td>
                      <div className="w-full justify-center flex items-center gap-5">
                        <AiOutlineEdit
                          className="hover:text-primary cursor-pointer"
                          onClick={() => {
                            setSelectedUser(user);
                            setShowEdit(true);
                          }}
                        />
                        <BsTrash        onClick={() => {
                            setSelectedUser(user);
                            setShowDel(true);
                          }} className="hover:text-red-700 cursor-pointer" />
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
      {/* Modal to edit */}
      <Modal
        title={`Editing ${selectedUser.name}`}
        isOpen={showEdit}
        setIsOpen={setShowEdit}
      >
        <Edit
          change={change}
          setChange={setChange}
          selectedUser={selectedUser}
          showEdit={showEdit}
          setShowEdit={setShowEdit}
        />
      </Modal>

      {/* Modal to delete */}
           <Modal
        title={`Delete ${selectedUser.name}`}
        isOpen={showDel}
        setIsOpen={setShowDel}
      >
        <Delete
          change={change}
          setChange={setChange}
          selectedUser={selectedUser}
          showDel={showEdit}
          setShowDel={setShowDel}
        />
      </Modal>
    </div>
  );
}

export default CurrentUsers;
