import { Navigate, Outlet } from "react-router";

import { useContext } from "react";

// import { useAuth } from "../firebase";

import { AuthContext } from "../contexts/AuthContext";

// state from redux

const useAuth = () => {
  const { user } = useContext(AuthContext);

  if (user.length) {
    return true;
  } else {
    return false;
  }
};

export const ProtectedRoutes = () => {
  const isAuth = useAuth();

  return isAuth ? <Outlet /> : <Navigate to="/" />;
};
