import React, { useState } from "react";

import { addPartner, storage } from "../../../firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

import Notify from "../../../components/notifications/Notify";

function AddPartner({ addParterModal, setAddPartnerModal }) {
  const [loading, setLoading] = useState(false);
  const [allowUpload, setAllowUpload] = useState(true);

  const formHandler = (e) => {
    e.preventDefault();
    const file = e.target[0].files[0];
    uploadFile(file);
  };

  const [progress, setProgress] = useState(0);

  const uploadFile = (file) => {
    setLoading(true);
    setAllowUpload(true);
    //
    if (!file) return;
    const storageRef = ref(storage, `/partners/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(prog);
      },
      (err) => {
        setAddPartnerModal(false);
        Notify("error", err);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          // Store name and url in database
          let data = {
            name: file.name,
            url,
          };
          addPartner(data)
            .then(() => {
              setLoading(false);
              setAddPartnerModal(false);
              Notify("success", "Uploaded successfully");
            })
            .catch((e) => {
              Notify("error", "Error occured");
            });
        });
      }
    );
  };

  return (
    <div>
      <form onSubmit={formHandler}>
        <input
          type="file"
          className="w-full mb-10 border border-dashed border-gray p-10 text-center mx-auto rounded-xl"
        />
        {progress > 0 && (
          <div className="my-10">
            <h3 className="text-normal ">Uploading: {progress}%</h3>
            <p className="font-light text-gray-700 mt-5">
              Modal would close automatically. Please wait...
            </p>
          </div>
        )}

        <button
          disabled={loading && allowUpload}
          className="btn-primary-admin-outline"
        >
          {loading ? "Uploading..." : "Upload"}
        </button>
      </form>
    </div>
  );
}

export default AddPartner;
