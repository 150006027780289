import React, { useContext, Fragment, useState, useEffect } from "react";

import Layout from "../../components/layout/Layout";

import { AuthContext } from "../../contexts/AuthContext";

import Modal from "../../components/modal/Modal";

import { getAllLogs } from "../../firebase";

import Notify from "../../components/notifications/Notify";

import dayjs from "dayjs";
import { format, lastDayOfMonth } from "date-fns";

import Loader from "../../components/loader/Loader";

import { DayPicker } from "react-day-picker";

import "react-day-picker/dist/style.css";

// dayjs localizes
var localizedFormat = require("dayjs/plugin/localizedFormat");

function Logs() {
  dayjs.extend(localizedFormat);
  const { user } = useContext(AuthContext);
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);

  const [change, setChange] = useState(false);

  // DATE SELECTORS
  // Time stuff
  const [showPicker, setShowPicker] = useState(false);

  // Get first and last day of current month
  const today = new Date();
  const firstDateOfMonth = format(today, "yyyy-MM-01");
  const lastDateOfMonth = format(lastDayOfMonth(today), "yyyy-MM-dd");
  // date filters
  const [startDate, setStartDate] = useState(firstDateOfMonth);
  const [endDate, setEndDate] = useState(lastDateOfMonth);
  const [selected, setSelected] = useState(new Date());

  // setting date range
  useEffect(() => {
    // Start Date

    if (selected !== undefined && selected.from !== undefined) {
      setStartDate(format(new Date(selected.from), "yyyy-MM-dd"));
    }

    // End Date
    if (selected !== undefined && selected.to !== undefined) {
      setEndDate(format(new Date(selected.to), "yyyy-MM-dd"));
    }
  }, [selected]);

  useEffect(() => {
    setLoading(true);
    getAllLogs(startDate, endDate)
      .then((snapshot) => {
        let all = [];
        snapshot.docs.forEach((doc) => {
          all.push({ ...doc.data(), id: doc.id });
        });
        setLogs(all);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(true);
        Notify("error", "Error fetching logs");
      });
  }, [change]);

  return (
    <Layout>
      {user[0].permissions.includes("logs") && (
        <div>
          {" "}
          <div className="flex justify-between items-center">
            <h3>Audit Logs</h3>

            {/* <button className="btn-primary-admin">All</button> */}
          </div>
          {/* Logs */}
          {loading ? (
            <Loader />
          ) : (
            <Fragment>
              <div className="flex justify-between my-5">
                <div className="flex gap-5 items-center text-base font-medium">
                  <div>
                    <span className="text-xs">Entries from </span>
                    <h4>
                      {dayjs(startDate).format("LL")} to{" "}
                      {dayjs(endDate).format("LL")}
                    </h4>
                  </div>

                  <Modal
                    title="Select date range"
                    isOpen={showPicker}
                    setIsOpen={setShowPicker}
                  >
                    <DayPicker
                      className="text-sm font-normal mx-auto inline-block"
                      mode="range"
                      numberOfMonths={2}
                      selected={selected}
                      onSelect={setSelected}
                    />
                    <button
                      onClick={() => {
                        setShowPicker(false);
                        setChange(!change);
                      }}
                      className="btn-primary-admin"
                    >
                      Set range
                    </button>
                  </Modal>
                </div>
                <div className="flex items-center gap-4">
                  <button
                    onClick={() => {
                      setSelected(new Date());
                      setShowPicker(true);
                    }}
                    className="btn-primary-admin-outline"
                  >
                    Select Date Range
                  </button>
                </div>
              </div>

              <div className="my-6">
                <table class="w-full font-normal text-sm text-left text-gray-700">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-100 ">
                    <tr>
                      <th scope="col" class="px-6 py-3">
                        Date
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Type
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Action
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Rep
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Rep Email
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Details
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {logs.map((entry) => {
                      return (
                        <tr class="bg-white border-b py-5">
                          <td class="px-6 py-4">
                            {dayjs(
                              new Date(entry.createdAt.seconds * 1000)
                            ).format("llll")}
                          </td>
                          <td class="px-6 py-4 ">
                            <span
                              className={`
                          ${entry.type === "client" && "bg-blue-300"}
                          ${entry.type === "product" && "bg-green-300"} 
                            ${entry.type === "user" && "bg-violet-300"} 
                      
                   p-2 text-xs font-base rounded-lg`}
                            >
                              {entry.type.toUpperCase()}
                            </span>
                          </td>
                          <td class="px-6 py-4">{entry.action}</td>
                          <td class="px-6 py-4">{entry.rep}</td>
                          <td class="px-6 py-4">{entry.repEmail}</td>

                          {/* View */}
                          <td class="px-6 py-4 cursor-pointer hover:text-primary">
                            {entry.details}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Fragment>
          )}
        </div>
      )}
    </Layout>
  );
}

export default Logs;
