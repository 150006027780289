import React, {  Fragment, useContext } from "react";

import Layout from "../../components/layout/Layout";
import { AuthContext } from "../../contexts/AuthContext";

import Border from "../../components/border/Border";

import AllClients from "./components/AllClients";

function Clients() {
  const { user } = useContext(AuthContext);



  return (
    <Layout>
      {user[0].permissions.includes("clients") && (
        <Fragment>
          {" "}
          <div className="flex justify-between items-center">
            <h3>Clients</h3>
          </div>
          <Border />
         <AllClients />
        </Fragment>
      )}
    </Layout>
  );
}

export default Clients;
