import React, { Fragment, useState, useEffect } from "react";

import { CSVLink } from "react-csv";
//
import dayjs from "dayjs";
import { format, lastDayOfMonth } from "date-fns";

import SingleClient from "./SingleClient";

import { VscEmptyWindow } from "react-icons/vsc";

import Notify from "../../../components/notifications/Notify";

import { getUnResolvedClients } from "../../../firebase";
import Loader from "../../../components/loader/Loader";

import Modal from "../../../components/modal/Modal";

import { DayPicker } from "react-day-picker";

import "react-day-picker/dist/style.css";

function Open() {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [change, setChange] = useState(false);

  // DATE SELECTORS
  // Time stuff
  const [showPicker, setShowPicker] = useState(false);

  // Get first and last day of current month
  const today = new Date();
  const firstDateOfMonth = format(today, "yyyy-MM-01");
  const lastDateOfMonth = format(lastDayOfMonth(today), "yyyy-MM-dd");

  // date filters
  const [startDate, setStartDate] = useState(firstDateOfMonth);
  const [endDate, setEndDate] = useState(lastDateOfMonth);
  const [selected, setSelected] = useState(new Date());

  useEffect(() => {
    setLoading(true);
    getUnResolvedClients(startDate, endDate)
      .then((snapshot) => {
        let all = [];
        snapshot.docs.forEach((doc) => {
          all.push({ ...doc.data(), id: doc.id });
        });
        setClients(all);
        setLoading(false);
      })
      .catch((e) => {
        console.log("e", e);
        Notify("error", "Error fetching clients");
      });
  }, [change]);

  // Export Data to CSV
  const [csvData, setCsvData] = useState([]);

  useEffect(() => {
    // EXPORTING THE DATA INTO CSV FILE
    const toCSV = [];
    for (let i = 0; i < clients?.length; i++) {
      let obj = {};
      obj["date"] = dayjs(new Date(clients[i].createdAt.seconds * 1000)).format(
        "ll"
      );
      obj["name"] = clients[i].name;
      obj["email"] = clients[i].email;
      obj["contact"] = clients[i].number;
      obj["request"] = clients[i].request.toUpperCase();
      obj["notes"] = clients[i].notes;

      // add to svg
      toCSV.push(obj);
    }
    setCsvData(toCSV);
  }, [clients]);

  const reportHeader = [
    { label: "Date", key: "date" },
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Contact", key: "contact" },
    { label: "Request Type", key: "request" },
    { label: "Notes", key: "notes" },
  ];

  const csvFile = {
    filename: `${
      "unresolved-client-requests-" + dayjs(new Date()).format("ll") + ".csv"
    }`,
    headers: reportHeader,
    data: csvData,
  };

  // setting date range
  useEffect(() => {
    // Start Date

    if (selected !== undefined && selected.from !== undefined) {
      setStartDate(format(new Date(selected.from), "yyyy-MM-dd"));
    }

    // End Date
    if (selected !== undefined && selected.to !== undefined) {
      setEndDate(format(new Date(selected.to), "yyyy-MM-dd"));
    }
  }, [selected]);

  return (
    <Fragment>
      <div class="relative overflow-x-auto">
        {loading ? (
          <Loader />
        ) : (
          <div>
            <div className="flex justify-between my-5">
              <div className="flex gap-5 items-center text-base font-medium">
                <div>
                  <span className="text-xs">Entries from </span>
                  <h4>
                    {dayjs(startDate).format("LL")} to{" "}
                    {dayjs(endDate).format("LL")}
                  </h4>
                </div>

                <Modal
                  title="Select date range"
                  isOpen={showPicker}
                  setIsOpen={setShowPicker}
                >
                  <DayPicker
                    className="text-sm font-normal mx-auto inline-block"
                    mode="range"
                    numberOfMonths={2}
                    selected={selected}
                    onSelect={setSelected}
                  />
                  <button
                    onClick={() => {
                      setShowPicker(false);
                      setChange(!change);
                    }}
                    className="btn-primary-admin"
                  >
                    Set range
                  </button>
                </Modal>
              </div>
              <div className="flex items-center gap-4">
                <button
                  onClick={() => {
                    setSelected(new Date());
                    setShowPicker(true);
                  }}
                  className="btn-primary-admin-outline"
                >
                  Select Date Range
                </button>
                <CSVLink {...csvFile} type="button">
                  <button className="btn-primary-admin">
                    Export Data to CSV
                  </button>
                </CSVLink>
              </div>
            </div>
            <div>
              {!loading && clients.length === 0 ? (
                <div className="min-h-[30vh] w-full mx-auto text-center p-20 flex items-center">
                  <div className="mx-auto w-full text-center">
                    {" "}
                    <VscEmptyWindow
                      className="inline-block text-center mx-auto"
                      size={40}
                    />
                    <h4 className="font-medium text-3xl mt-5">No Entries</h4>
                    <p className="font-light text-sm mt-1">
                      No entries found for selected range
                    </p>
                  </div>
                </div>
              ) : (
                <Fragment>
                  <table class="w-full font-normal text-sm text-left text-gray-700">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-100 ">
                      <tr>
                        <th scope="col" class="px-6 py-3">
                          Date
                        </th>
                        <th scope="col" class="px-6 py-3">
                          Name
                        </th>
                        <th scope="col" class="px-6 py-3">
                          Email
                        </th>
                        <th scope="col" class="px-6 py-3">
                          Contact
                        </th>
                        <th scope="col" class="px-6 py-3">
                          Type
                        </th>
                        <th scope="col" class="px-6 py-3">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {!loading &&
                        clients.map((client, index) => {
                          return (
                            <SingleClient
                              change={change}
                              setChange={setChange}
                              id={index}
                              client={client}
                            />
                          );
                        })}
                    </tbody>
                  </table>
                </Fragment>
              )}
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default Open;
