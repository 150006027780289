import React, { useState, useEffect } from "react";

import { addClient } from "../../../firebase";

import Notify from "../../../components/notifications/Notify";

import capitalize from "../../../utils/Capitalize";

function ContactForm({ searchValue }) {
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [number, setNumber] = useState(null);
  const [request, setRequest] = useState("general");
  const [notes, setNotes] = useState(null);
  const [header, setHeader] = useState(null);

  // loading
  const [loading, setLoading] = useState(false);

  // datae
  const date = new Date();

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  let currentDate = `${year}-${month}-${day}`;

  const send = () => {
    Notify("info", "Sending...");
    setLoading(true);
    let data = {
      name,
      email,
      number,
      request,
      notes,
      status: true,
      date: currentDate,
    };
    addClient(data)
      .then(() => {
        setLoading(false);
        setName("");
        setEmail("");
        setRequest("");
        setNotes("");
        setNumber("");
        Notify("success", "Sent successfully!");
      })
      .catch((e) => {
        setLoading(false);
        Notify("error", "Sending failed. Please try again");
      });
  };

  // set search value to option
  useEffect(() => {
    //  remove ? from beginning
    if (searchValue !== "") {
      const val = searchValue.substring(1);
      setRequest(val);
      setHeader(`Get Started with ${capitalize(val)}`);
    } else {
      setRequest("general");
    }
  }, [searchValue]);

  return (
    <div className="-mt-10 mb-32 bg-white border border-gray-400 shadow-lg max-w-5xl mx-auto rounded-none md:rounded-3xl py-14 px-5 md:px-14">
      <div>
        <h3 className="font-semibold text-4xl mb-5">
          {header === null ? " We’d love to hear from you" : header}
        </h3>
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          send();
        }}
      >
        <div className="my-5">
          <label>Full Name*</label>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            type="text"
            placeholder="Full Name"
            className="form-input-full outline outline-1 outline-gray-400"
          />
        </div>

        {/* Email & Contact */}
        <div className="flex my-10 justify-between gap-5">
          <div className="flex-1">
            <label>Email Address*</label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              type="email"
              placeholder="Email address"
              className="form-input-full outline outline-1 outline-gray-400"
            />
          </div>
          <div className="flex-1 ">
            <label>Contact Number*</label>
            <input
              required
              onChange={(e) => setNumber(e.target.value)}
              value={number}
              type="number"
              placeholder="Contact Number"
              className="form-input-full outline outline-1 outline-gray-400"
            />
          </div>
        </div>

        {/* Enquriy */}
        <div className="my-5">
          <label>Enquiry / Request *</label>
          <select
            onChange={(e) => setRequest(e.target.value)}
            required
            value={request}
            className="border-none outline outline-1 outline-gray-400 bg-white border  border-primary text-primary block w-full  rounded-xl py-4 px-6 mb-3 leading-tight focus:border-secondary"
          >
            <option value="general">General Enquiry</option>
            <option value="claim">Make Claim</option>
            <option value="callback">Callback Request</option>
            <option value="vago">VAGO</option>
            <option value="taxigo">Taxigo</option>
          </select>
        </div>

        {/* Message */}
        <div className="my-5">
          <label>Notes</label>
          <textarea
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            rows={6}
            type="text"
            placeholder="Additional notes"
            className="form-input-full outline outline-1 outline-gray-400"
          />
        </div>

        <button
          type="submit"
          disabled={loading}
          className="btn-primary w-full mt-5"
        >
          {loading ? "Please wait..." : "Send"}
        </button>
      </form>
    </div>
  );
}

export default ContactForm;
