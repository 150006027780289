import React, { useEffect } from "react";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import CTA from "../../components/cta/CTA";
import Border from "../../components/border/Border";

function About() {
  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="h-[28rem] bg-primary bg-chat bg-cover bg-center bg-opacity-90">
        <div className="pt-7">
          <Navbar />
        </div>

        <div className="header text-white  mt-20 text-center">
          <h2 className="font-bold text-5xl">About Us</h2>
          <p className="font-normal w-full px-5 md:px-0 md:max-w-xl md:mx-auto mt-5">
            VA Network Agency Ltd is positioned to connect consumers with
            relevant financial services through strategic alliances and
            partnerships.
          </p>
        </div>
      </div>

      <div className="my-20 max-w-5xl mx-auto">
        <p className="px-10 md:px-0">
          Financial services consumers in Ghana and Africa at large are rapidly
          becoming more sophisticated, spoilt for choice and gravitate more
          towards simple, convenient, affordable and profitable products and
          services. With the growing penetration of mobile phones and by
          extension digital/electronic transactions, VA Network Agency Ltd, is
          positioned to connect consumers with relevant financial services
          through strategic alliances and partnerships.{" "}
        </p>
      </div>

      <div className="my-16 md:my-32 px-10 md:px-0 max-w-5xl mx-auto flex flex-col md:flex-row justify-between gap-10">
        <div className="flex-1 bg-purple rounded-xl"></div>
        <div className="flex-1">
          <span className="pill">About Us</span>
          <h5 className="mt-10 mb-2 text-2xl font-semibold text-primary">
            Our Mission
          </h5>
          <p className="mb-10">
            To be a significant Pan African Digital Financial Service
            distributor in Africa.
          </p>
          <Border />
          <h5 className="mt-4 mb-2 text-2xl font-semibold text-primary">
            Our Vision
          </h5>
          <p className="mb-20">
            To build, protect and sustain our clients, wealth by offering
            premium digital financial solutions that meet their aspirations at
            every stage of their lives
          </p>
        </div>
      </div>

      <CTA />
      <Footer />
    </div>
  );
}

export default About;
