import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import Info from "./components/Info";
import ContactForm from "./components/ContactForm";

import "./index.css";

// routerdom
import { useLocation } from "react-router-dom";

function Contact() {
  // scroll to top
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation();

  return (
    <div className="">
      <div className="pt-5 pb-16 bg-chat bg-center bg-cover bg-no-repeat text-white">
        <Navbar />
        <h4 className="text-center text-4xl mt-16 font-bold">Get in touch</h4>
        <p className="text-center text-sm font-normal">
          Contact us for enquiries, claims and requests
        </p>

        <Info />
      </div>

      <ContactForm searchValue={location.search} />

      <Footer />
    </div>
  );
}

export default Contact;
