import React from "react";

import { FiLoader } from "react-icons/fi";

function Loader() {
  return (
    <div className="min-h-[30vh] w-full mx-auto text-center p-20 flex items-center">
      <div className="mx-auto">
        {" "}
        <FiLoader className="inline-block text-center mx-auto" size={40} />
        <h4 className="font-medium text-3xl mt-5">Loading</h4>
        <p className="font-light text-sm mt-1">Please wait...</p>
      </div>
    </div>
  );
}

export default Loader;
