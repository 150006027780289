import React, { useState, useEffect } from "react";

import Layout from "../../../components/layout/Layout";
import Border from "../../../components/border/Border";

// firebase
import { addProduct } from "../../../firebase";
import { storage } from "../../../firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

import Notify from "../../../components/notifications/Notify";

import { IoIosCloseCircleOutline } from "react-icons/io";

import { getAllPartners } from "../../../firebase";

import { useNavigate } from "react-router-dom";

import { produce } from "immer";
import Modal from "../../../components/modal/Modal";

function AddProduct() {
  const navigate = useNavigate();

  const [name, setName] = useState(null);
  const [shortDesc, setShortDesc] = useState(null);
  const [about, setAbout] = useState(null);
  const [terms, setTerms] = useState(null);
  const [termsUrl, setTermsUrl] = useState(null);
  const [file, setFile] = useState(null);

  const [currentPartners, setCurrentParners] = useState([]);
  const [progress, setProgress] = useState(0);

  const [loading, setLoading] = useState(false);

  const [productLoad, setProductLoad] = useState(false);

  useEffect(() => {
    getAllPartners()
      .then((snapshot) => {
        let all = [];
        snapshot.docs.forEach((doc) => {
          all.push({ ...doc.data(), id: doc.id });
        });
        setCurrentParners(all);
      })
      .catch((e) => {
        Notify("error", "Error occured");
      });
  }, []);

  // Eligibility
  const [elig, setElig] = useState([
    { id: 0, name: "" },
    { id: 1, name: "" },
  ]);

  // faqs
  const [faqs, setFaqs] = useState([
    { id: 0, question: "", answer: "" },
    { id: 1, question: "", answer: "" },
  ]);

  // partner
  const [selectPartners, setSelectPartners] = useState([{ id: 0, url: "" }]);

  // Deletes for dynamic inputs
  const deleteEligInput = (eligeID) => {
    const allEligs = [...elig];
    const newElig = allEligs.filter((item) => item.id !== eligeID);
    setElig(newElig);
  };

  const deleteFaqInput = (faqID) => {
    const allFaqs = [...faqs];
    const newFaqs = allFaqs.filter((item) => item.id !== faqID);
    setFaqs(newFaqs);
  };

  const deletePartnerInput = (partnerID) => {
    const allPartners = [...selectPartners];
    const newPartners = allPartners.filter((item) => item.id !== partnerID);
    setSelectPartners(newPartners);
  };

  // Creating product function
  const add = () => {
    setProductLoad(true);

    // upload file first
    if (!file) return;
    const storageRef = ref(
      storage,
      `/products/${name.toLowerCase()}/terms/${file.name}`
    );
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(prog);
      },
      (err) => {
        Notify("error", err);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          // Store name and url in database
          // Create Product here
          setTermsUrl(url);

          let data = {
            name,
            shortDesc,
            desc: about,
            eligibility: elig,
            faqs: faqs,
            partners: selectPartners,
            terms: { name: file.name, termsDesc: terms, url },
          };
          addProduct(data)
            .then(() => {
              setProductLoad(false);
              Notify("success", "Created Product Successfully");
              setShortDesc(null);
              setAbout(null);
              setProductLoad(false);
              navigate(`/manage/${name.toLowerCase()}`);
            })
            .catch((e) => {
              setLoading(false);
              Notify("error", "Error occured");
            });
        });
      }
    );
  };

  return (
    <Layout>
      {/* PRODUCT UPLOAD MODAL */}
      <Modal
        title="Creating Product"
        isOpen={productLoad}
        setIsOpen={setProductLoad}
      >
        <h3 className="text-center mx-auto">Creating Product... Please wait</h3>
        {progress > 0 && progress < 99 && (
          <div className="my-10">
            <h3 className="text-normal ">
              Uploading Terms & Conditions: {progress}%
            </h3>
            <p className="font-light text-gray-700 mt-5">
              Modal would close automatically. Please wait...
            </p>
          </div>
        )}
        {progress === 100 && (
          <div>
            <h3 className="text-normal ">Saving Product information</h3>
            <p className="font-light text-gray-700 mt-5">
              Modal would close automatically. Please wait...
            </p>
          </div>
        )}
      </Modal>

      <div className="flex justify-between items-center">
        <h3>Add Product</h3>

        <button
          onClick={() => add()}
          disabled={loading}
          className="btn-primary-admin"
        >
          Create Product
        </button>
      </div>
      <Border />
      <div className="mb-5">
        {" "}
        <span className="pill">About Product</span>
      </div>

      <p className="prod-label mt-4">Product Name</p>
      <input
        placeholder="Name of Product"
        className="admin-input"
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      <p className="prod-label">Short Description</p>
      <textarea
        onChange={(e) => setShortDesc(e.target.value)}
        value={shortDesc}
        placeholder="Short Description for the product page header"
        rows={3}
        type="text"
        className="admin-input"
      />

      <p className="prod-label">Full Description (About)</p>
      <textarea
        value={about}
        placeholder="All information about the product (summary)"
        onChange={(e) => setAbout(e.target.value)}
        rows={5}
        type="text"
        className="admin-input"
      />

      {/* ELIGIBILITY AND REQUIREMENTS */}
      <div className="mt-20 mb-5">
        {" "}
        <span className="pill">Eligibility / Requirements</span>
      </div>

      <div className="mt-4">Eligibility / Requirements</div>
      <div className="mt-5 mb-2">
        {elig.map((single, i) => {
          return (
            <div className="flex gap-10 items-center">
              <div className="flex-1">
                <input
                  type="text"
                  className="admin-input"
                  value={single.name}
                  placeholder="Elibigility Criteria"
                  onChange={(e) => {
                    const name = e.target.value;
                    setElig((currentField) =>
                      produce(currentField, (value) => {
                        value[i].name = name;
                      })
                    );
                  }}
                />
              </div>

              {elig.length > 1 && (
                <IoIosCloseCircleOutline
                  className="hover:text-red-600 font-normal cursor-pointer"
                  onClick={() => deleteEligInput(single.id)}
                />
              )}
            </div>
          );
        })}
      </div>
      <button
        onClick={() => {
          setElig((eligs) => [
            ...eligs,
            {
              id: Math.floor(Math.random() * 90 + 10),
              name: "",
            },
          ]);
        }}
        className="btn-primary-admin-outline"
      >
        Add Eligibility +{" "}
      </button>

      {/* PARTNERS */}
      <div className="mt-20 mb-5">
        <span className="pill">Partners</span>
      </div>

      <div>
        <div className="mt-5 mb-2">
          {selectPartners.map((single, i) => {
            return (
              <div className="flex gap-10 items-center">
                <div className="flex-1">
                  <select
                    className="w-full border border-gray-600 p-4 mb-5 text-sm rounded-xl"
                    value={single.name}
                    onChange={(e) => {
                      setSelectPartners((currentField) =>
                        produce(currentField, (value) => {
                          value[i].url = e.target.value;
                        })
                      );
                    }}
                  >
                    {currentPartners.map((single) => {
                      return <option value={single.url}>{single.name}</option>;
                    })}
                  </select>
                </div>

                {selectPartners.length > 1 && (
                  <IoIosCloseCircleOutline
                    className="hover:text-red-600 font-normal cursor-pointer"
                    onClick={() => deletePartnerInput(single.id)}
                  />
                )}
              </div>
            );
          })}
        </div>
        <button
          onClick={() => {
            setSelectPartners((selectPartners) => [
              ...selectPartners,
              {
                id: Math.floor(Math.random() * 90 + 10),
                url: "",
              },
            ]);
          }}
          className="btn-primary-admin-outline"
        >
          Add Partner +{" "}
        </button>
      </div>

      {/* TERMS AND CONDITIONS */}
      <div className="mt-20 mb-5">
        <span className="pill">Terms & Conditions</span>
      </div>
      <div>
        <p className="prod-label mt-4">Terms and Conditions Write up</p>
        <textarea
          onChange={(e) => setTerms(e.target.value)}
          value={terms}
          placeholder="Terms and conditions write up before client downloads document"
          rows={3}
          type="text"
          className="admin-input"
        />

        <p className="text-sm font-normal mt-10 mb-5">
          Upload Terms and Conditions Document
        </p>
        <input
          type="file"
          onChange={(e) => {
            setFile(e.target.files[0]);
          }}
          placeholder="Terms and Conditions document"
          className="w-full font-normal text-sm mb-10 border border-dashed border-gray p-10 text-center mx-auto rounded-xl"
        />
      </div>

      {/* FREQUENTLY ASKED QUESTIONS */}
      <div className="mt-20 mb-5">
        {" "}
        <span className="pill">Product FAQs</span>
      </div>
      <div>Product FAQs</div>
      <div className="my-2">
        {faqs.map((single, i) => {
          return (
            <div className="flex gap-10 items-center my-5">
              <div className="flex-1">
                <input
                  type="text"
                  className="admin-input"
                  value={single.question}
                  placeholder="Question"
                  onChange={(e) => {
                    const question = e.target.value;
                    setFaqs((currentField) =>
                      produce(currentField, (value) => {
                        value[i].question = question;
                      })
                    );
                  }}
                />
                <textarea
                  rows={3}
                  type="text"
                  className="admin-input"
                  value={single.answer}
                  placeholder="Answer"
                  onChange={(e) => {
                    const answer = e.target.value;
                    setFaqs((currentField) =>
                      produce(currentField, (value) => {
                        value[i].answer = answer;
                      })
                    );
                  }}
                />
              </div>

              {faqs.length > 1 && (
                <IoIosCloseCircleOutline
                  className="hover:text-red-600 font-normal cursor-pointer"
                  onClick={() => deleteFaqInput(single.id)}
                />
              )}
            </div>
          );
        })}
      </div>
      <button
        onClick={() => {
          setFaqs((faqs) => [
            ...faqs,
            {
              id: Math.floor(Math.random() * 90 + 10),
              question: "",
              answer: "",
            },
          ]);
        }}
        className="btn-primary-admin-outline"
      >
        Add FAQ +{" "}
      </button>
    </Layout>
  );
}

export default AddProduct;
