import React from "react";

import { VscDebugBreakpointConditional } from "react-icons/vsc";

function Eligibility({ product }) {
  return (
    <div>
      <h3 className="text-2xl font-bold text-primary">
        Eligibility / Requirement
      </h3>

      <div className="mt-5">
        {product.eligibility.map((single) => {
          return (
            <p id={single.name} className="my-2 text-primary">
              {" "}
              <VscDebugBreakpointConditional className="inline mr-2" />
              {single.name}
            </p>
          );
        })}
      </div>
    </div>
  );
}

export default Eligibility;
