import React from "react";
import { NavLink } from "react-router-dom";

import error from "../../assets/icons/error.svg";

function NotFound() {
  return (
    <div className="h-screen w-full bg-purple items-center p-10 md:p-48 flex flex-col md:flex-row justify-around gap-10 md:gap-20">
      <div className="flex-1">
        <h2 className="text-7xl font-bold text-primary">Uh oh...</h2>
        <p className=" text-primary text-lg mt-2">
          Well, this isn't ideal. We can't seem to find the requested page
        </p>
        <p className="font-light text-lg mt-2 text-primary ">Error | 404</p>
        <NavLink to="/">
          <button className="btn-primary mt-10 md:mt-24">Back to Home</button>
        </NavLink>
      </div>
      <div className="flex-1">
        <img src={error} alt="Not found 404" />
      </div>
    </div>
  );
}

export default NotFound;
