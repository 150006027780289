import React, { useContext, useState, useEffect } from "react";

import Layout from "../../components/layout/Layout";

import {
  getResolvedClients,
  getUnResolvedClients,
  productCount,
  resetPassword,
} from "../../firebase";

import { NavLink } from "react-router-dom";

import { BsBoxSeam, BsPatchCheck } from "react-icons/bs";
import { BiUserPin } from "react-icons/bi";

import dayjs from "dayjs";
import { format, lastDayOfMonth } from "date-fns";

import Border from "../../components/border/Border";

import { AuthContext } from "../../contexts/AuthContext";
import Notify from "../../components/notifications/Notify";

function Dashboard() {
  const { user, removeUser } = useContext(AuthContext);

  const [totalProducts, setTotalProducts] = useState(null);
  const [newClients, setNewClients] = useState(null);
  const [resolved, setResolved] = useState(null);

  const [loading, setLoading] = useState(false);

  const today = new Date();
  const firstDateOfMonth = format(today, "yyyy-MM-01");
  const lastDateOfMonth = format(lastDayOfMonth(today), "yyyy-MM-dd");

  // date filters
  const [startDate, setStartDate] = useState(firstDateOfMonth);
  const [endDate, setEndDate] = useState(lastDateOfMonth);

  useEffect(() => {
    productCount()
      .then((querySnapshot) => {
        setTotalProducts(querySnapshot.size);
      })
      .catch((e) => {
        console.log("error,e", e);
      });

    // new clients
    getUnResolvedClients(startDate, endDate)
      .then((querySnapshot) => {
        setNewClients(querySnapshot.size);
      })
      .catch((e) => {
        console.log("error,e", e);
      });

    getResolvedClients(startDate, endDate)
      .then((querySnapshot) => {
        setResolved(querySnapshot.size);
      })
      .catch((e) => {
        console.log("error,e", e);
      });
  }, []);

  // greeting
  const date = new Date();
  let hours = date.getHours();
  let status =
    hours < 12 ? "Morning" : hours <= 18 && hours >= 12 ? "Afternoon" : "Night";

  // reset password
  const resetPass = () => {
    setLoading(true);
    resetPassword(user[0].email)
      .then(() => {
        Notify("success", "Check your email for a password reset link");

        setTimeout(() => {
          removeUser();
        }, 3000);
        setLoading(false);
      })
      .catch((e) => {
        Notify("error", "Error occured");
        setLoading(false);
      });
  };

  return (
    <Layout>
      <div className="flex justify-between items-center">
        <h3>Dashboard</h3>
        <NavLink to="/">
          <button className="btn-primary text-sm font-normal">
            Visit Website
          </button>
        </NavLink>
      </div>
      <Border />

      {/* Greeting */}
      <div className="border font-semibold border-gray-400 rounded-2xl p-10">
        <h2>
          {" "}
          Good {status}, {user[0].name}
        </h2>
        <h5 className="font-medium text-base text-gray-500">{user[0].email}</h5>
      </div>

      {/* Analytics */}
      <div className="grid grid-cols-3 gap-10 my-10">
        {/* Products */}
        <div className="border border-gray-300 rounded-2xl p-5">
          <div className="bg-indigo-400 rounded-2xl w-1/4 h-12 flex items-center justify-center">
            <BsBoxSeam className="inline text-white" size={20} />
          </div>
          <h4 className="font-normal text-base mb-3 mt-4 ">
            Number of Products
          </h4>
          <h1 className="mb-5 text-5xl">{totalProducts}</h1>
          <Border />
          <p className="font-normal text-sm">Total</p>
        </div>

        {/* unresolved client requests */}
        <div className="border border-gray-300 rounded-2xl p-5">
          <div className="bg-red-400 rounded-2xl w-1/4 h-12 flex items-center justify-center">
            <BiUserPin className="inline text-white" size={20} />
          </div>
          <h4 className="font-normal text-base mb-3 mt-4 ">
            Unresolved Client Requests
          </h4>
          <h1 className="mb-5 text-5xl">{newClients}</h1>
          <Border />
          <p className="font-normal text-sm">
            {dayjs(startDate).format("MMMM YYYY")}
          </p>
        </div>

        {/* resovled */}
        <div className="border border-gray-300 rounded-2xl p-5">
          <div className="bg-green-400 rounded-2xl w-1/4 h-12 flex items-center justify-center">
            <BsPatchCheck className="inline text-white" size={20} />
          </div>
          <h4 className="font-normal text-base mb-3 mt-4 ">
            Resolved Client Requests
          </h4>
          <h1 className="mb-5 text-5xl">{resolved}</h1>
          <Border />
          <p className="font-normal text-sm">
            {dayjs(startDate).format("MMMM YYYY")}
          </p>
        </div>
      </div>

      {/* Personal Administration */}

      <h4>Personal Settings</h4>

      <div className="border w-2/3 mt-5 border-gray-300 rounded-2xl px-5 py-3 mb-32">
        <h4 className="font-base text-lg mb-1 mt-4 ">Reset your password</h4>
        <p className="font-normal text-base mb-3 ">
          You would be logged out instantly and a password reset link would be
          sent to <span className="font-bold"> {user[0].email} </span>
          <br /> Click the link in your email to reset your password
        </p>

        <button
          disabled={loading}
          onClick={() => resetPass()}
          className="btn-primary-admin"
        >
          Reset Password
        </button>
      </div>
    </Layout>
  );
}

export default Dashboard;
