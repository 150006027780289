import React, { useState, useContext } from "react";

import Modal from "../../../components/modal/Modal";

import { createLog, updateEligProduct } from "../../../firebase";

import { IoIosCloseCircleOutline } from "react-icons/io";

import { AuthContext } from "../../../contexts/AuthContext";

import { AiOutlineEdit } from "react-icons/ai";

import { produce } from "immer";

// notify
import Notify from "../../../components/notifications/Notify";

function EligUpdate({ product, change, setChange }) {
  const { user } = useContext(AuthContext);

  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // Eligibility
  const [elig, setElig] = useState(product.eligibility);

  // Deletes for dynamic inputs
  const deleteEligInput = (eligeID) => {
    const allEligs = [...elig];
    const newElig = allEligs.filter((item) => item.id !== eligeID);
    setElig(newElig);
  };

  // handle update
  const handleUpdate = () => {
    let id = product.name.toLowerCase();
    setLoading(true);
    updateEligProduct(id, elig)
      .then(() => {
        let data = {
          type: "product",
          action: "Update",
          rep: user[0].name,
          repEmail: user[0].email,
          details: "Updated " + product.name + "'s eligibility",
        };
        createLog(data)
          .then(() => {
            setIsOpen(false);
            setChange(!change);
            Notify("success", "Updated!");
          })
          .catch((e) => {
            setLoading(false);
            Notify("error", "Error occured");
          });
      })
      .catch((e) => {
        setIsOpen(false);
        Notify("error", "Error occured");
      });
  };

  return (
    <div className="inline">
      <AiOutlineEdit
        onClick={() => setIsOpen(true)}
        size={14}
        className="ml-5 inline w-8 h-8 bg-blue-200 hover:bg-blue-400 rounded-full p-2 cursor-pointer"
      />

      {/* update function */}
      <Modal
        title="Eligibility / Requirements"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        <div>
          {" "}
          {elig.map((single, i) => {
            return (
              <div className="flex gap-10 items-center">
                <div className="flex-1">
                  <input
                    type="text"
                    className="admin-input"
                    value={single.name}
                    placeholder="Elibigility Criteria"
                    onChange={(e) => {
                      const name = e.target.value;
                      setElig((currentField) =>
                        produce(currentField, (value) => {
                          value[i].name = name;
                        })
                      );
                    }}
                  />
                </div>

                {elig.length > 1 && (
                  <IoIosCloseCircleOutline
                    className="hover:text-red-600 font-normal cursor-pointer"
                    onClick={() => deleteEligInput(single.id)}
                  />
                )}
              </div>
            );
          })}
        </div>
        <div className="text-left mb-10">
          <button
            onClick={() => {
              setElig((eligs) => [
                ...eligs,
                {
                  id: Math.floor(Math.random() * 90 + 10),
                  name: "",
                },
              ]);
            }}
            className="btn-primary-admin-outline"
          >
            Add Eligibility +{" "}
          </button>
        </div>

        <button
          disabled={loading}
          onClick={() => handleUpdate()}
          className="btn-primary-admin my-5"
        >
          Update
        </button>
      </Modal>
    </div>
  );
}

export default EligUpdate;
