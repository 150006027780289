import React from "react";

// logos
import mtn from "../../../assets/logos/mtn.png";
import itc from "../../../assets/logos/itc.png";
import united from "../../../assets/logos/united.png";
import vanguard from "../../../assets/logos/vangaurd.png";

function Partners() {
  return (
    <div className="my-36 px-10 md:px-0 flex flex-col md:flex-row gap-10 justify-between  items-center max-w-4xl mx-auto">
      <div className="flex-1">
        <span className="pill">Our Partners & Investors</span>
        <h4 className="text-3xl md:text-4xl font-bold text-primary mt-8 mb-4">
          We're in partnership with the best
        </h4>
        <p>
          Strategic alliances and partnerships to connect you to relevant
          financial services
        </p>
      </div>

      {/* Logos */}
      <div className="flex-1 grid grid-cols-2 items-center gap-2">
        <img className="w-32 mx-auto" src={mtn} alt="mtn" />
        <img className="w-48 mx-auto" src={itc} alt="IT Consortium Gh" />
        <img className="w-48 mx-auto" src={united} alt="United Trustees" />
        <img className="w-24 mx-auto" src={vanguard} alt="Vanguard Assurance" />
      </div>
    </div>
  );
}

export default Partners;
