import React, { useState, useContext } from "react";
import Modal from "../../../components/modal/Modal";
import Border from "../../../components/border/Border";

import { AuthContext } from "../../../contexts/AuthContext";

import { createLog, updateClient } from "../../../firebase";
import Notify from "../../../components/notifications/Notify";
import dayjs from "dayjs";
// dayjs localizes
var localizedFormat = require("dayjs/plugin/localizedFormat");

function SingleClient({ client, change, setChange }) {
  const { user } = useContext(AuthContext);

  dayjs.extend(localizedFormat);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const updateStatus = () => {
    setLoading(true);
    updateClient(client.id)
      .then(() => {
        let data = {
          type: "client",
          action: "Update",
          rep: user[0].name,
          repEmail: user[0].email,
          details:
            client.name +
            "'s " +
            client.request +
            " request " +
            " marked as resolved",
        };
        createLog(data)
          .then(() => {
            setLoading(false);
            setShow(false);
            setChange(!change);
            Notify("success", "Marked as resolved");
          })
          .catch((e) => {
            setLoading(false);
            setShow(false);
            Notify("error", "Error occured");
          });
      })
      .catch((e) => {
        setLoading(false);
        setShow(false);
        Notify("error", "Error occured");
      });
  };

  return (
    <tr class="bg-white border-b py-5">
      <td class="px-6 py-4">
        {dayjs(new Date(client.createdAt.seconds * 1000)).format("ll")}
      </td>
      <td class="px-6 py-4">{client.name}</td>
      <td class="px-6 py-4">{client.email}</td>
      <td class="px-6 py-4">{client.number}</td>
      <td class="px-6 py-4 ">
        <span
          className={`
          ${client.request === "general" && "bg-blue-300"}
          ${client.request === "vago" && "bg-green-300"} 
          ${client.request === "claim" && "bg-red-300"}
          ${client.request === "callback" && "bg-orange-300"}
          ${client.request === "taxigo" && "bg-indigo-300"}
           p-2 text-xs font-semibold rounded-xl`}
        >
          {client.request.toUpperCase()}
        </span>
      </td>

      {/* View */}
      <td
        onClick={() => setShow(true)}
        class="px-6 py-4 cursor-pointer hover:text-primary"
      >
        View
      </td>

      {/* Modal */}
      <Modal title="Details" isOpen={show} setIsOpen={setShow}>
        <div className="flex justify-around items-center">
          <div className="text-left">
            {" "}
            <p className="text-2xl font-semibold">{client.name}</p>
            <p>Contact {client.number}</p>
          </div>
          <div className="text-right">
            <p className="text-sm font-light">Email Address</p>
            <p>{client.email}</p>
          </div>
        </div>
        <Border />
        <span className="pill">Request Type</span>
        <p className="mt-3 font-bold text-xl">{client.request.toUpperCase()}</p>
        <Border />
        <span className="pill">Notes</span>
        <p className="mt-3">{client.notes}</p>
        <Border />

        {client.status ? (
          <button
            onClick={() => updateStatus()}
            disabled={loading}
            className="btn-primary-admin-outline"
          >
            {loading ? "Processing" : " Mark as resolved"}
          </button>
        ) : (
          <button disabled className="btn-primary-admin-outline">
            Request Resolved
          </button>
        )}
      </Modal>
    </tr>
  );
}

export default SingleClient;
