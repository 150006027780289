import React, { useState, Fragment, useEffect, useContext } from "react";

import Layout from "../../components/layout/Layout";

import Border from "../../components/border/Border";

import Modal from "../../components/modal/Modal";

import AddPartner from "./components/AddPartner";
import { getAllPartners } from "../../firebase";
import Notify from "../../components/notifications/Notify";

import { AuthContext } from "../../contexts/AuthContext";
import Loader from "../../components/loader/Loader";

function Partners() {
  const { user } = useContext(AuthContext);

  const [addPartnerModal, setAddPartnerModal] = useState(false);

  const [allPartners, setAllPartners] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getAllPartners()
      .then((snapshot) => {
        let all = [];
        snapshot.docs.forEach((doc) => {
          all.push({ ...doc.data(), id: doc.id });
        });
        setAllPartners(all);
        setLoading(false);
      })
      .catch((e) => {
        Notify("error", "Error occured");
      });
  }, []);

  return (
    <Layout>
      {user[0].permissions.includes("products") && (
        <Fragment>
          <div className="flex justify-between items-center">
            <h3>Partners</h3>
            <button
              onClick={() => setAddPartnerModal(true)}
              disabled={loading}
              className="btn-primary-admin"
            >
              Add New Partner
            </button>
          </div>
          <Border />

          {loading && <Loader />}

          {/* All Partners */}
          {!loading && allPartners.length > 0 && (
            <div className="my-10 grid grid-cols-4 gap-10">
              {allPartners.map((partner) => {
                return (
                  <div className="border border-gray-300 p-5 rounded-lg">
                    <div className="h-2/3 items-center flex">
                      {" "}
                      <img
                        className="mx-auto"
                        src={partner.url}
                        alt={partner.name}
                      />
                    </div>

                    <Border />
                    <h3 className="mb-10 h-1/3 text-center text-base font-normal">
                      {partner.name}
                    </h3>
                  </div>
                );
              })}
            </div>
          )}

          {/* Modal */}
          <Modal
            title="Add New Partner"
            isOpen={addPartnerModal}
            setIsOpen={setAddPartnerModal}
          >
            <AddPartner
              addPartnerModal={addPartnerModal}
              setAddPartnerModal={setAddPartnerModal}
            />
          </Modal>
        </Fragment>
      )}
    </Layout>
  );
}

export default Partners;
