import React, { useState, useContext } from "react";

import Border from "../../../components/border/Border";

import Notify from "../../../components/notifications/Notify";
import { createLog, updateUser } from "../../../firebase";

import { AuthContext } from "../../../contexts/AuthContext";

function Edit({ selectedUser, showEdit, setShowEdit, setChange, change }) {
  // context
  const { user } = useContext(AuthContext);

  // data
  const [email, setEmail] = useState(selectedUser.email);
  const [name, setName] = useState(selectedUser.name);
  const [loading, setLoading] = useState(false);

  // checkboxes
  const [clientsP, setClientsP] = useState(
    selectedUser.permissions.includes("clients")
  );
  const [productsP, setProductsP] = useState(
    selectedUser.permissions.includes("products")
  );
  const [logsP, setLogsP] = useState(selectedUser.permissions.includes("logs"));

  // superuser
  const [superUser, setSuperUser] = useState(selectedUser.userType);

  async function handleEdit() {
    setLoading(true);
    // check all permissions and update
    // concat returns a new updated array
    let permissions = [];
    if (clientsP) {
      permissions.push("clients");
    }

    if (productsP) {
      permissions.push("products");
    }

    if (logsP) {
      permissions.push("logs");
    }
    if (superUser) {
      permissions.push("superuser");
    }

    let id = selectedUser.id;
    // store user in db
    let data = {
      email,
      name,
      permissions: permissions,
      userType: superUser ? "super" : "regular",
    };

    updateUser(id, data)
      .then(() => {
        createLog({
          type: "user",
          rep: user[0].name,
          repEmail: user[0].email,
          action: "Update",
          details: "Updated " + selectedUser.name + "'s details",
        })
          .then(() => {
            setChange(!change);
            setShowEdit(false);
            setLoading(false);
            Notify("success", "Success");
          })
          .catch((e) => {
            setShowEdit(false);
            setLoading(false);
            Notify("error", "Error occured");
          });
      })
      .catch((e) => {
        setShowEdit(false);
        setLoading(false);
        Notify("error", "Error occured");
      });
  }

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleEdit();
        }}
      >
        {/* NAME */}
        <div className="flex">
          <div className="w-full">
            <input
              required
              className="admin-input"
              type="text"
              placeholder="Full Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>
        {/* EMAIL ADDRESS */}
        <div className="flex">
          <div className="w-full">
            <input
              required
              disabled
              className="admin-input"
              type="text"
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>

        {/* ROLES AND PERMISSIONS */}
        <Border />
        <div className="my-5">
          <h3 className="text-left text-lg font-bold mb-3">Permissions</h3>
          <div className="flex gap-5 items-center">
            {/* clients */}
            <div className="flex items-center gap-2">
              <input
                className="inline-block"
                type="checkbox"
                id="clients"
                onChange={(e) => {
                  setClientsP(e.target.checked);
                }}
                defaultChecked={clientsP}
                name="clients"
              />
              <span className="inline-block" for="clients">
                Clients
              </span>
            </div>
            {/* Products */}
            <div className="flex items-center gap-2">
              <input
                className="inline-block"
                type="checkbox"
                onClick={() => setProductsP(!productsP)}
                checked={productsP}
              />
              <span className="inline-block" for="clients">
                Products & Partners
              </span>
            </div>
            {/* Logs */}
            <div className="flex items-center gap-2">
              <input
                className="inline-block"
                type="checkbox"
                onClick={() => setLogsP(!logsP)}
                checked={logsP}
              />
              <span className="inline-block" for="clients">
                Logs
              </span>
            </div>
          </div>
          <h3 className="text-left text-lg font-bold my-3">User Type</h3>
        </div>
        <div className="flex items-center gap-2 mb-5">
          <input
            className="inline-block"
            type="checkbox"
            onClick={() => setSuperUser(!superUser)}
            checked={superUser}
          />
          <span className="inline-block" for="clients">
            Super User (Can create other users and assign permissions)
          </span>
        </div>
        <button
          disabled={loading}
          className={` ${
            loading ? "cursor-not-allowed" : null
          } btn-primary w-full mt-3 `}
        >
          {loading ? "Please wait..." : `Save Changes`}
        </button>
      </form>
    </div>
  );
}

export default Edit;
