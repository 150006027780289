import React from "react";

import { motion } from "framer-motion";
import AnimatedText from "../../../utils/AnimatedText";

import chat from "../../../assets/icons/chat.svg";
import car from "../../../assets/icons/car.svg";

import { BsArrowRight } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import AnimatedTitle from "./AnimatedTitle";

function ProductsPreview() {
  const products = [
    {
      name: "VAGO",
      icon: chat,
      description:
        "VAGO is a FINSURE (financial service and insurance) product which aims at providing on-demand e-money loans with embedded insurance for qualified mobile money agents and merchants in partnership with MTN Mobile Money Limited, banks and other financial institutions.",
    },
    {
      name: "Taxigo",
      icon: car,
      description:
        "Taxigo is a bundled financial solution that provides a means for drivers to conveniently contribute towards their motor insurance whilst enjoying other insurance and savings benefits.",
    },
  ];

  const container = {
    visible: {
      transition: {
        staggerChildren: 0.025,
      },
    },
  };

  return (
    <div className="bg-[#F8F7FC] py-20">
      <div className="w-full md:max-w-5xl mx-auto">
        <div className="text-center my-5">
          <span className="pill">Products</span>
        </div>

        <h2 className="my-5 mb-20 text-primary text-3xl text-center font-bold">
          Financial Solutions for You
        </h2>

        <div className="px-10 md:px-0">
          {products.map((product, index) => {
            return (
              <div
                className={` ${
                  index % 2 !== 0 ? "md:flex-row-reverse" : null
                } flex flex-col-reverse md:flex-row justify-between gap-20 mb-32 items-center`}
              >
                <div className="flex-1">
                  <h3 className="text-2xl mb-3 text-primary font-bold">
                    {product.name}
                  </h3>
                  {/* <motion.div
                    className="App"
                    initial="hidden"
                    animate="visible"
                    variants={container}
                  >
                    <div className="container">
                      {placeholderText.map((item, index) => {
                        return <AnimatedText {...item} key={index} />;
                      })}
                    </div>
                  </motion.div> */}

                  <AnimatedTitle text={product.description} />

                  {/* <p>{product.description}</p> */}
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <NavLink to={`product/${product.name.toLowerCase()}`}>
                      <button className="btn-primary text-sm mt-5 flex items-center">
                        See {product.name}{" "}
                        <BsArrowRight className="inline ml-4" />
                      </button>
                    </NavLink>
                  </motion.div>
                </div>
                <div className="flex-1 text-center">
                  <img src={product.icon} alt="illustration" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ProductsPreview;
