import React, { useState, useEffect } from "react";

import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";

import { BsArrowRight } from "react-icons/bs";

import { NavLink, useParams } from "react-router-dom";

import { getSingleProduct } from "../../firebase";
import ProductLoading from "./components/ProductLoading";

import CTA from "../../components/cta/CTA";
import Eligibility from "./components/Eligibility";
import Partners from "./components/Partners";
import Terms from "./components/Terms";
import FAQS from "./components/FAQS";

function Product() {
  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();

  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);

  // fetch document data
  useEffect(() => {
    setLoading(true);
    getSingleProduct(id)
      .then((doc) => {
        setLoading(false);
        setProduct(doc.data());
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [id]);

  if (!loading) {
    return (
      <div>
        <div
          className={`
          ${
            product.name.toLowerCase() === "vago" &&
            "bg-vago bg-center bg-contain"
          } 
          ${
            product.name.toLowerCase() === "taxigo" &&
            "bg-taxigo bg-cover bg-center"
          } 
            h-[28rem] bg-primary   bg-opacity-90 `}
        >
          <div className="pt-7">
            <Navbar />
          </div>

          <div className="header text-white  mt-20 text-center">
            <h2 className="font-bold text-5xl ">
              {" "}
              {product.name.toUpperCase()}
            </h2>
            <p className="font-normal w-full px-5 md:px-0 md:max-w-xl md:mx-auto mt-5">
              {product.shortDesc}
            </p>
            <NavLink to={`/contact/?${product.name.toLowerCase()}`}>
              <button className="btn-secondary mt-5">
                Get Started with {product.name}{" "}
                <BsArrowRight className="inline ml-4" />
              </button>
            </NavLink>
          </div>
        </div>

        <div className="w-full mx-5 md:max-w-6xl  md:mx-auto my-20">
          <h3 className="text-2xl font-bold text-primary mb-5">
            About {product.name}
          </h3>
          <p>{product.desc}</p>
          <NavLink to={`/contact/?${product.name.toLowerCase()}`}>
            <button className="btn-secondary mt-5">
              Get Started with {product.name}{" "}
              <BsArrowRight className="inline ml-4" />
            </button>
          </NavLink>

          {/* Eligibility, partners and terms */}

          <div className="flex flex-col md:flex-row flex-between gap-10 mt-20">
            {product.eligibility && (
              <div className="product-bubble flex-1">
                <Eligibility product={product} />
              </div>
            )}

            {product.partners && (
              <div className="product-bubble flex-1">
                <Partners product={product} />
              </div>
            )}
          </div>
          {product.terms && (
            <div className="mt-10 mb-20 product-bubble">
              <Terms product={product} />
            </div>
          )}
        </div>

        {product.faqs && <FAQS product={product} />}

        <CTA />
        <Footer />
      </div>
    );
  } else {
    return <ProductLoading />;
  }
}

export default Product;
