import React, { useState, useContext } from "react";

import Notify from "../../../components/notifications/Notify";

import { AuthContext } from "../../../contexts/AuthContext";

import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Border from "../../../components/border/Border";

import { addUser, createUser, createLog } from "../../../firebase";

function Create({ showCreate, setShowCreate }) {
  // context
  const { user } = useContext(AuthContext);

  // data
  const [email, setEmail] = useState(null);
  const [name, setName] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  // checkboxes
  const [clientsP, setClientsP] = useState(false);
  const [productsP, setProductsP] = useState(false);
  const [logsP, setLogsP] = useState(false);

  // superuser
  const [superUser, setSuperUser] = useState(false);

  async function handleCreate() {
    setLoading(true);
    // check all permissions and update
    // concat returns a new updated array
    let permissions = [];
    if (clientsP) {
      permissions.push("clients");
    }

    if (productsP) {
      permissions.push("products");
    }

    if (logsP) {
      permissions.push("logs");
    }
    if (superUser) {
      permissions.push("superuser");
    }

    createUser(email, password)
      .then((cred) => {
        // store user in db
        let data = {
          uid: cred.user.uid,
          email,
          name,
          permissions: permissions,
          userType: superUser ? "super" : "regular",
        };

        addUser(data)
          .then(() => {
            createLog({
              type: "user",
              rep: user[0].name,
              repEmail: user[0].email,
              action: "Create",
              details: "Created " + name + "'s account",
            })
              .then(() => {
                setLoading(false);
                setShowCreate(false);
                Notify("success", "Created user succesfully");
              })
              .catch((e) => {
                setLoading(false);
                Notify("error", "Error occured");
              });
          })
          .catch((e) => {
            setLoading(false);
            Notify("error", "Error occured");
          });
      })
      .catch((err) => {
        // email already in use
        setShowCreate(false);
        setLoading(false);
        if (err.code === "auth/email-already-in-use") {
          Notify("error", "Email is already in use");
        }
        // weak password
        if (err.code === "auth/weak-password") {
          Notify(
            "error",
            "Weak password. Password should be at least 6 characters"
          );
        }
        // no email
        if (err.code === "auth/missing-email") {
          Notify("error", "Enter your email");
        }
        // general error

        if (err.code === "auth/internal-error") {
          Notify("error", "Error. Try again");
        }
      });
  }

  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleCreate();
        }}
      >
        {/* NAME */}
        <div className="flex">
          <div className="w-full">
            <input
              required
              className="admin-input"
              type="text"
              placeholder="Full Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>
        {/* EMAIL ADDRESS */}
        <div className="flex">
          <div className="w-full">
            <input
              required
              className="admin-input"
              type="text"
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        {/* PASSWORD */}
        <div className="flex">
          <div style={{ position: "relative" }} className="w-full">
            <input
              required
              className="admin-input"
              type={passwordVisible ? "input" : "password"}
              placeholder="Password"
              value={password}
              onFocus={() => {}}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div
              style={{
                cursor: "pointer",
                position: "absolute",
                top: 20,
                right: 32,
              }}
              onClick={() => {
                setPasswordVisible(!passwordVisible);
              }}
            >
              {passwordVisible ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
            </div>
          </div>
        </div>

        {/* ROLES AND PERMISSIONS */}
        <Border />
        <div className="my-5">
          <h3 className="text-left text-lg font-bold mb-3">Permissions</h3>
          <div className="flex gap-5 items-center">
            {/* clients */}
            <div className="flex items-center gap-2">
              <input
                className="inline-block"
                type="checkbox"
                id="clients"
                onChange={(e) => {
                  setClientsP(e.target.checked);
                }}
                defaultChecked={clientsP}
                name="clients"
              />
              <span className="inline-block" for="clients">
                Clients
              </span>
            </div>
            {/* Products */}
            <div className="flex items-center gap-2">
              <input
                className="inline-block"
                type="checkbox"
                onClick={() => setProductsP(!productsP)}
                checked={productsP}
              />
              <span className="inline-block" for="clients">
                Products & Partners
              </span>
            </div>
            {/* Logs */}
            <div className="flex items-center gap-2">
              <input
                className="inline-block"
                type="checkbox"
                onClick={() => setLogsP(!logsP)}
                checked={logsP}
              />
              <span className="inline-block" for="clients">
                Logs
              </span>
            </div>
          </div>
          <h3 className="text-left text-lg font-bold my-3">User Type</h3>
        </div>
        <div className="flex items-center gap-2 mb-5">
          <input
            className="inline-block"
            type="checkbox"
            onClick={() => setSuperUser(!superUser)}
            checked={superUser}
          />
          <span className="inline-block" for="clients">
            Super User (Can create other users and assign permissions)
          </span>
        </div>
        <button
          disabled={loading}
          className={` ${
            loading ? "cursor-not-allowed" : null
          } btn-primary w-full mt-3 `}
        >
          {loading ? "Please wait..." : "Create User"}
        </button>
      </form>
    </div>
  );
}

export default Create;
