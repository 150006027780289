import React, { useState, useContext } from "react";

import Modal from "../../../components/modal/Modal";

import { AuthContext } from "../../../contexts/AuthContext";

import { IoIosCloseCircleOutline } from "react-icons/io";

import { produce } from "immer";

import { AiOutlineEdit } from "react-icons/ai";
import { createLog, updateAboutProduct } from "../../../firebase";

// notify
import Notify from "../../../components/notifications/Notify";

function ProductUpdate({ product, change, setChange }) {
  const { user } = useContext(AuthContext);

  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  console.log("product partnerts", product.partners);

  // partner
  const [selectPartners, setSelectPartners] = useState(product.partners);
  const [currentPartners, setCurrentPartners] = useState(product.partners);

  const deletePartnerInput = (partnerID) => {
    const allPartners = [...selectPartners];
    const newPartners = allPartners.filter((item) => item.id !== partnerID);
    setSelectPartners(newPartners);
  };

  // handle update
  const handleUpdate = () => {
    let id = product.name.toLowerCase();
    setLoading(true);
    // updateAboutProduct(id, about, shortDesc)
    //   .then(() => {
    //     let data = {
    //       type: "product",
    //       action: "Update",
    //       rep: user[0].name,
    //       repEmail: user[0].email,
    //       details: "Updated " + product.name + "'s about description",
    //     };
    //     createLog(data)
    //       .then(() => {
    //         setLoading(false);
    //         setIsOpen(false);
    //         setChange(!change);
    //         Notify("success", "Updated!");
    //       })
    //       .catch((e) => {
    //         setLoading(false);
    //         Notify("error", "Error occured");
    //       });
    //   })
    //   .catch((e) => {
    //     setIsOpen(false);
    //     Notify("error", "Error occured");
    //   });
  };

  return (
    <div className="inline">
      <AiOutlineEdit
        onClick={() => setIsOpen(true)}
        size={14}
        className="ml-5 inline w-8 h-8 bg-blue-200 hover:bg-blue-400 rounded-full p-2 cursor-pointer"
      />

      {/* update function */}
      <Modal title="Partners" isOpen={isOpen} setIsOpen={setIsOpen}>
        <div>
          <div className=" grid grid-cols-3 gap-10 my-10 mb-2">
            {selectPartners.map((single, i) => {
              return (
                <div>
                  <img src={single.url} alt="partner" />
                </div>
              );
            })}
          </div>
          {/* <button
            onClick={() => {
              setSelectPartners((selectPartners) => [
                ...selectPartners,
                {
                  id: Math.floor(Math.random() * 90 + 10),
                  url: "",
                },
              ]);
            }}
            className="btn-primary-admin-outline"
          >
            Add Partner +{" "}
          </button> */}
        </div>
        <button
          disabled
          onClick={() => handleUpdate()}
          className="btn-primary-admin my-5"
        >
          Update
        </button>
      </Modal>
    </div>
  );
}

export default ProductUpdate;
