import React, { useEffect, useState } from "react";

import { getAllProducts } from "../../../firebase";

import { NavLink } from "react-router-dom";
import Loader from "../../../components/loader/Loader";

function AllProducts() {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    setLoading(true);
    getAllProducts()
      .then((snapshot) => {
        let all = [];
        snapshot.docs.forEach((doc) => {
          all.push({ ...doc.data(), id: doc.id });
        });
        setProducts(all);
        setLoading(false);
      })
      .catch((e) => {
        console.log("couldn't get products", e);
      });
  }, []);

  if (!loading) {
    return (
      <div>
        <div className="grid gap-5 grid-cols-2">
          {products.map((single) => {
            return (
              <div className=" bg-white border border-gray-200 p-10 rounded-xl">
                <h3 className="text-2xl font-semibold"> {single.name}</h3>
                <p className="font-light mt-2 text-sm">{single.shortDesc}</p>
                <NavLink to={`/manage/${single.name.toLowerCase()}`}>
                  <button className="btn-primary-admin mt-3">View</button>
                </NavLink>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else {
    return <Loader />;
  }
}

export default AllProducts;
