import React, { useState, useContext } from "react";

import Modal from "../../../components/modal/Modal";

import { AuthContext } from "../../../contexts/AuthContext";

import { AiOutlineEdit } from "react-icons/ai";
import { createLog, updateAboutProduct } from "../../../firebase";

// notify
import Notify from "../../../components/notifications/Notify";

function AboutUpdate({ product, change, setChange }) {
  const { user } = useContext(AuthContext);

  const [shortDesc, setShortDesc] = useState(product.shortDesc);
  const [about, setAbout] = useState(product.desc);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // handle update
  const handleUpdate = () => {
    let id = product.name.toLowerCase();
    setLoading(true);
    updateAboutProduct(id, about, shortDesc)
      .then(() => {
        let data = {
          type: "product",
          action: "Update",
          rep: user[0].name,
          repEmail: user[0].email,
          details: "Updated " + product.name + "'s about description",
        };
        createLog(data)
          .then(() => {
            setLoading(false);
            setIsOpen(false);
            setChange(!change);
            Notify("success", "Updated!");
          })
          .catch((e) => {
            setLoading(false);
            Notify("error", "Error occured");
          });
      })
      .catch((e) => {
        setIsOpen(false);
        Notify("error", "Error occured");
      });
  };

  return (
    <div className="inline">
      <AiOutlineEdit
        onClick={() => setIsOpen(true)}
        size={14}
        className="ml-5 inline w-8 h-8 bg-blue-200 hover:bg-blue-400 rounded-full p-2 cursor-pointer"
      />

      {/* update function */}
      <Modal
        title="Short Description & About"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        <div>
          <p className="prod-label">Short Description</p>
          <textarea
            onChange={(e) => setShortDesc(e.target.value)}
            value={shortDesc}
            placeholder="Short Description for the product page header"
            rows={3}
            type="text"
            className="admin-input"
          />

          <p className="prod-label mb-4">
            Full Description of {product.name} (About)
          </p>
          <textarea
            value={about}
            placeholder="All information about the product (summary)"
            onChange={(e) => setAbout(e.target.value)}
            rows={7}
            type="text"
            className="admin-input"
          />
        </div>
        <button
          disabled={loading}
          onClick={() => handleUpdate()}
          className="btn-primary-admin my-5"
        >
          Update
        </button>
      </Modal>
    </div>
  );
}

export default AboutUpdate;
