import React from "react";

function Partners({ product }) {
  return (
    <div>
      <h3 className="text-2xl font-bold text-primary">Partners</h3>
      <div className="grid grid-cols-2 my-5">
        {product.partners.map((single) => {
          return (
            <div className="m-2 flex items-center justify-start">
              <img className="mx-auto" src={single.url} alt="patner" />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Partners;
