import React, { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";

import { Menu } from "react-feather";

import Drawer from "./Drawer";

import { IoIosArrowDown } from "react-icons/io";

import logo from "../../assets/logos/logo.png";

function Navbar({ partnersSection }) {
  const [isOpen, setIsOpen] = useState(false);

  const scrollDown = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <Fragment>
      {/* desktop nav */}
      <div className="hidden  mx-auto w-full md:max-w-5xl bg-primary bg-opacity-95 hover:bg-opacity-100  text-white  py-3 px-4 rounded-full md:flex justify-between items-center">
        {/* Logo */}
        <NavLink to="/">
          <img className="pl-4 w-40" src={logo} alt="VA Networks Logo" />
        </NavLink>
        {/* Links */}
        <ul className="hidden md:block nav-links">
          <li>
            <div className="inline">
              <button className="peer hover:text-secondary">
                Products <IoIosArrowDown className="inline" />
              </button>

              <div class="hidden text-primary absolute peer-hover:flex hover:flex w-[200px] rounded-xl flex-col bg-white drop-shadow-lg">
                <NavLink to="/product/taxigo">
                  <p className="px-5 py-3 hover:bg-purple rounded-xl">Taxigo</p>
                </NavLink>
                <NavLink to="/product/vago">
                  <p className="px-5 py-3 hover:bg-purple rounded-xl">VAGO</p>
                </NavLink>
              </div>
            </div>
          </li>
          <li>
            {" "}
            <NavLink className="hover:text-secondary" to="/about">
              About Us
            </NavLink>
          </li>
          <li onClick={() => scrollDown(partnersSection)}>Partners</li>
        </ul>

        {/* Button */}
        <NavLink to="/contact">
          <button className="btn-primary-white text-primary">Contact Us</button>
        </NavLink>
      </div>

      {/* mobile nav */}
      <div className="px-3 items-center  md:hidden absolute w-full text-purple bg-primary flex justify-between py-3 top-0">
        <NavLink to="/">
          <img className="w-32" src={logo} alt="VA Networks Logo" />
        </NavLink>

        <Menu
          onClick={() => setIsOpen(!isOpen)}
          className=" dark:text-primary-light text-primary-dark inline"
        />
      </div>

      <Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
        <ul className="">
          <li className="nav-item-mobile">
            <NavLink to="/product/vago">Vago</NavLink>
          </li>
          <li className="nav-item-mobile">
            <NavLink to="/product/taxigo">Taxigo</NavLink>
          </li>
          <li className="nav-item-mobile">
            <NavLink to="/about">About Us</NavLink>
          </li>
          <li className="nav-item-mobile">
            <NavLink to="/contact">Contact us</NavLink>
          </li>
        </ul>
      </Drawer>
    </Fragment>
  );
}

export default Navbar;
