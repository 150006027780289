// Import the functions you need from the SDKs you need
import { useState, useEffect } from "react";

import { initializeApp } from "firebase/app";

import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
} from "firebase/auth";

import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  doc,
  addDoc,
  updateDoc,
  deleteDoc,
  orderBy,
  where,
  query,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";

import { getStorage } from "firebase/storage";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBGa6Sl5sElnUsz6ay4iX6md14qnqbxzMU",
  authDomain: "va-networks-1.firebaseapp.com",
  projectId: "va-networks-1",
  storageBucket: "va-networks-1.appspot.com",
  messagingSenderId: "821214986749",
  appId: "1:821214986749:web:798eedae2c43aefac05994",
  measurementId: "G-LB8BX9GGHG",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// auth
export const auth = getAuth(app);

// Storage
export const storage = getStorage(app);

// firestore
const db = getFirestore();

// Authentication function

// Email & Password SignIn
export function signIn(email, password) {
  return signInWithEmailAndPassword(auth, email, password);
}

// Creating user
// Email & Password SignUp
export function createUser(email, password) {
  return createUserWithEmailAndPassword(auth, email, password);
}

export function addUser(data) {
  // const colRef = collection(db, "products");
  const docRef = doc(db, "users", data.uid);
  return setDoc(docRef, {
    ...data,
    createdAt: serverTimestamp(),
  });
}

export function getSingleUser(id) {
  const docRef = doc(db, "users", id);
  return getDoc(docRef);
}

// password reset function
export function resetPassword(email) {
  return sendPasswordResetEmail(auth, email);
}

// Custom auth hook
export function useAuth() {
  const [currentUser, setCurrentUser] = useState();
  //
  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => setCurrentUser(user));
    return unsub;
  }, []);
  return currentUser;
}

// PRODUCTS
export function getSingleProduct(id) {
  const docRef = doc(db, "products", id);
  return getDoc(docRef);
}

export function addProduct(data) {
  // const colRef = collection(db, "products");
  const docRef = doc(db, "products", data.name.toLowerCase());
  return setDoc(docRef, {
    ...data,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  });
}

export function deleteProduct(id) {
  const docRef = doc(db, "products", id);
  return deleteDoc(docRef);
}

export function getAllProducts() {
  const colRef = collection(db, "products");
  return getDocs(colRef);
}

// product count
export function productCount() {
  const colRef = collection(db, "products");
  return getDocs(colRef);
}

// PARTNERS
export function addPartner(data) {
  const docRef = doc(db, "partners", data.name.toLowerCase());
  return setDoc(docRef, { ...data, createdAt: serverTimestamp() });
}

export function getAllPartners() {
  const colRef = collection(db, "partners");
  return getDocs(colRef);
}

// PRODUCT INDIVIDUAL UPDATES
export function updateAboutProduct(id, about, shortDesc) {
  const docRef = doc(db, "products", id);
  return updateDoc(docRef, {
    desc: about,
    shortDesc: shortDesc,
    updatedAt: serverTimestamp(),
  });
}

export function updateEligProduct(id, elig) {
  const docRef = doc(db, "products", id);
  return updateDoc(docRef, { eligibility: elig, updatedAt: serverTimestamp() });
}

export function updateFaqsProduct(id, faqs) {
  const docRef = doc(db, "products", id);
  return updateDoc(docRef, { faqs: faqs, updatedAt: serverTimestamp() });
}

// TESTIMONIALS
export function getAllTestimonials() {
  const colRef = collection(db, "testimonials");
  getDocs(colRef).then((snapshot) => {
    let testimonials = [];
    snapshot.docs.forEach((doc) => {
      testimonials.push({ ...doc.data(), id: doc.id });
    });
  });
}

// CLIENTS
// Client Queries
const clientsColRef = collection(db, "clients");

const resovledClientsQuery = (startDate, endDate) =>
  query(
    clientsColRef,
    where("status", "==", false),
    where("createdAt", ">=", new Date(startDate)),
    where("createdAt", "<=", new Date(endDate)),
    orderBy("createdAt", "desc")
  );

const unresovledClientsQuery = (startDate, endDate) =>
  query(
    clientsColRef,
    where("status", "==", true),
    where("createdAt", ">=", new Date(startDate)),
    where("createdAt", "<=", new Date(endDate)),
    orderBy("createdAt", "desc")
  );

export function getResolvedClients(startDate, endDate) {
  return getDocs(resovledClientsQuery(startDate, endDate));
}

export function getUnResolvedClients(startDate, endDate) {
  return getDocs(unresovledClientsQuery(startDate, endDate));
}

const logsColRef = collection(db, "logs");

const logsQ = (startDate, endDate) =>
  query(
    logsColRef,

    where("createdAt", ">=", new Date(startDate)),
    where("createdAt", "<=", new Date(endDate)),
    orderBy("createdAt", "desc")
  );
// const logsQ = query(logsColRef, orderBy("createdAt", "desc"));

export function getAllLogs(startDate, endDate) {
  return getDocs(logsQ(startDate, endDate));
}

export function addClient(data) {
  return addDoc(clientsColRef, { ...data, createdAt: serverTimestamp() });
}

export function getAllClients() {
  const colRef = collection(db, "clients");
  return getDocs(colRef);
}

export function updateClient(id) {
  const docRef = doc(db, "clients", id);
  return updateDoc(docRef, {
    status: false,
  });
}

// get all users
export function getAllUsers() {
  const colRef = collection(db, "users");
  return getDocs(colRef);
}

// logs
// types: clients, product, partner
// by
// action: create, update
// details:
// createdAt
export function createLog(data) {
  const colRef = collection(db, "logs");
  return addDoc(colRef, { ...data, createdAt: serverTimestamp() });
}

export function updateUser(id, data) {
  const docRef = doc(db, "users", id);
  return updateDoc(docRef, {
    ...data,
  });
}

export function deleteUser(id) {
  const docRef = doc(db, "users", id);
  return deleteDoc(docRef);
}

// counts

// * Dashboard Analytics
// // * User creation (error with the same email)
// // * Changing the "number of years" to " number of regional presence"
// * change animation for the words
// * full words should fit on a line
// // *error occurred when marking a client request  as resolved
// // * centering of icons on the "contact us" page
// // *image for contact us page
// // * asking for info before live chat

// // * logs data with date filter
// * reset password for user
// * dashboard analytics count
