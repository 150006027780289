import React, { useState, useContext } from "react";

import Notify from "../../components/notifications/Notify";

import { getSingleUser, signIn } from "../../firebase";

import { Navigate } from "react-router";

// Link
import { NavLink } from "react-router-dom";

import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

import { AuthContext } from "../../contexts/AuthContext";

import { useNavigate } from "react-router-dom";

function Login() {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  // auth context
  const { addUser, user } = useContext(AuthContext);

  async function handleSignIn() {
    setLoading(true);
    signIn(email, password)
      .then((userCredential) => {
        //  const user = userCredential.user;
        getSingleUser(userCredential.user.uid)
          .then((doc) => {
            addUser(doc.data());
            Notify("success", "Logged In Successfully");
            setLoading(false);
            navigate("/dashboard");
          })
          .catch((e) => Notify("error", "Error occured"));
      })
      .catch((err) => {
        setLoading(false);
        // email already in use
        if (err.code === "auth/user-not-found") {
          Notify("error", "Not authorized");
        }
        // weak password
        if (err.code === "auth/wrong-password") {
          Notify("error", "Incorrect password");
        }
        // no email
        if (err.code === "auth/invalid-email") {
          Notify("error", "Enter your email");
        }
        // general error

        if (err.code === "auth/internal-error") {
          Notify("error", "Error. Try again");
        }
      });
  }

  if (user.length) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <div className="flex flex-col md:flex-row bg-gray-100 justify-around gap-10 md:gap-20 md:items-center h-screen pl-3 py-3 md:py-20 md:pl-20">
      <div className="flex-1 flex flex-col h-full justify-between">
        <NavLink to="/">
          <h3 className="text-lg md:text-lg font-bold text-primary">
            VA Network Agency
          </h3>
        </NavLink>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSignIn();
          }}
          className=""
        >
          <h1 className="text-2xl  md:text-3xl font-bold mb-5 md:mb-8">
            Management
          </h1>
          {/* EMAIL ADDRESS */}
          <div className="flex">
            <div className="w-full">
              <input
                required
                className="form-input-full"
                type="text"
                placeholder="Email Address"
                value={email}
                onFocus={() => {}}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          {/* PASSWORD */}
          <div className="flex">
            <div style={{ position: "relative" }} className="w-full">
              <input
                required
                className="form-input-full"
                type={passwordVisible ? "input" : "password"}
                placeholder="Password"
                value={password}
                onFocus={() => {}}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: 20,
                  right: 32,
                }}
                onClick={() => {
                  setPasswordVisible(!passwordVisible);
                }}
              >
                {passwordVisible ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
              </div>
            </div>
          </div>
          <button
            disabled={loading}
            className={` ${
              loading ? "cursor-not-allowed" : null
            } btn-primary w-full mt-3 `}
          >
            {loading ? "Please wait..." : "Log In"}
          </button>
        </form>
        <p className="font-light text-sm">
          Authorized personnel
          <span className="font-bold text-secondary-200"> only</span>
        </p>
      </div>
      <div className="flex-1 bg-hero bg-cover h-screen w-full bg-center"></div>
    </div>
  );
}

export default Login;
