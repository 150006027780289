import React from "react";

import { IoIosPeople } from "react-icons/io";

import { RiMapPin2Fill } from "react-icons/ri";
import { BsAwardFill } from "react-icons/bs";

function Facts() {
  const info = [
    {
      icon: <IoIosPeople size={40} />,
      stat: "24K",
      sub: "Satisfied Customers",
    },
    {
      icon: <RiMapPin2Fill size={26} />,
      stat: "7",
      sub: "Regional Presence",
    },
    {
      icon: <BsAwardFill size={30} />,
      stat: "Ghs200K+",
      sub: "Processed Annually",
    },
  ];
  return (
    <div className=" py-20 w-full bg-primary">
      <div className="w-full px-10 md:max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-10 md:gap-20">
        {info.map((single) => {
          return (
            <div className=" bg-purple bg-opacity-20 text-white rounded-2xl p-8">
              <div className="h-1/3"> {single.icon}</div>
              <h3 className="mt-5 h-1/3 text-4xl font-bold">{single.stat}</h3>
              <p className="font-normal h-1/3">{single.sub}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Facts;
