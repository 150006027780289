import React, { useState, Fragment, useContext } from "react";

import Modal from "../../components/modal/Modal";

import Layout from "../../components/layout/Layout";
import Border from "../../components/border/Border";
import Create from "./components/Create";
import CurrentUsers from "./components/CurrentUsers";

import { AuthContext } from "../../contexts/AuthContext";

function Settings() {
  const { user } = useContext(AuthContext);

  const [showCreate, setShowCreate] = useState(false);
  return (
    <Layout>
      {user[0].userType === "super" && (
        <Fragment>
          <div className="flex justify-between items-center">
            <h3>Settings</h3>

            <button
              onClick={() => setShowCreate(!showCreate)}
              className="btn-primary-admin"
            >
              Create New User
            </button>
          </div>
          <Border />
          <CurrentUsers />
          {/* Modal */}
          <Modal
            title="Create User"
            isOpen={showCreate}
            setIsOpen={setShowCreate}
          >
            <Create showCreate={showCreate} setShowCreate={setShowCreate} />
          </Modal>
        </Fragment>
      )}
    </Layout>
  );
}

export default Settings;
