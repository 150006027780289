import React, { useEffect } from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

// toast messages
import "react-toastify/dist/ReactToastify.css";

import { ToastContainer } from "react-toastify";

// auth context provider
import AuthContextProvider from "./contexts/AuthContext";

// offline online
import { Offline } from "react-detect-offline";

import Landing from "./app/Landing/Landing";

import Dashboard from "./admin/Dashboard/Dashboard";
import { ProtectedRoutes } from "./routes/ProtectedRoutes";
import NotFound from "./app/NotFound/NotFound";
import Login from "./admin/Login/Login";
import Contact from "./app/Contact/Contact";
import Product from "./app/Product/Product";
import Clients from "./admin/Clients/Clients";
import Products from "./admin/Products/Products";
import SingleProduct from "./admin/Products/components/SingleProduct";
import AddProduct from "./admin/Products/components/AddProduct";
import Partners from "./admin/Partners/Partners";
import About from "./app/About/About";
import Settings from "./admin/Settings/Settings";
import Logs from "./admin/Logs/Logs";

function App() {
  // implementing loader
  // fake authentication Promise
  const authenticate = () => {
    return new Promise((resolve) => setTimeout(resolve, 1000)); // 2 seconds
  };

  useEffect(() => {
    authenticate().then(() => {
      const ele = document.getElementById("ipl-progress-indicator");
      if (ele) {
        // fade out
        ele.classList.add("available");
        setTimeout(() => {
          // remove from DOM
          ele.outerHTML = "";
        }, 2000);
      }
    });
  }, []);
  return (
    <BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div>
        <Offline>
          <div className="w-full bg-pink-800 text-white text-center tracking-wide pt-3 pb-3 pl-3 pr-3">
            Offline. Please check your internet connection and reload
          </div>
        </Offline>
      </div>
      <AuthContextProvider>
        <Routes>
          <Route path="/" exact element={<Landing />} />
          <Route path="/contact" exact element={<Contact />} />
          <Route path="/about" exact element={<About />} />
          <Route path="/product/:id" exact element={<Product />} />

          <Route path="/manage" exact element={<Login />} />

          {/* PROTECTED ROUTES */}
          <Route element={<ProtectedRoutes />}>
            <Route path="/dashboard" exact element={<Dashboard />} />
            <Route path="/clients" exact element={<Clients />} />

            {/* Products */}
            <Route path="/products" exact element={<Products />} />
            <Route path="/manage/:id" exact element={<SingleProduct />} />
            <Route path="/manage/product/add" exact element={<AddProduct />} />

            {/* Partners */}
            <Route path="/partners" exact element={<Partners />} />

            {/* Settings */}
            <Route path="/settings" exact element={<Settings />} />
            <Route path="/logs" exact element={<Logs />} />
          </Route>

          {/* Not Found */}
          <Route path="*" exact element={<NotFound />} />
        </Routes>
      </AuthContextProvider>
    </BrowserRouter>
  );
}

export default App;
