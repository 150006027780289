import React from "react";

import { BsArrowRight } from "react-icons/bs";
import { NavLink } from "react-router-dom";

function CTA() {
  return (
    <div className="bg-afrik bg-fixed bg-center bg-cover text-white text-center md:mb-32 w-full py-32 md:py-32 md:px-32">
      <h3 className="text-4xl md:text-6xl font-bold text-center">
        We're here for you
      </h3>
      <p className="w-80 md:w-96 text-base md:text-lg mt-5 mx-auto">
        Get in touch with us for any enquiries or to sign up to our products
      </p>

      <NavLink to="/contact">
        <button className="btn-secondary mx-auto text-sm mt-10 flex items-center">
          Contact Us <BsArrowRight className="inline ml-4" />
        </button>
      </NavLink>
    </div>
  );
}

export default CTA;
