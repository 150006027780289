import React, { useEffect, useState } from "react";

import { VscDebugBreakpointConditional } from "react-icons/vsc";

import { BsArrowDownCircle } from "react-icons/bs";

import Modal from "../../../components/modal/Modal";

import { Disclosure } from "@headlessui/react";

import Layout from "../../../components/layout/Layout";
import Border from "../../../components/border/Border";

import { useParams } from "react-router-dom";
import { getSingleProduct } from "../../../firebase";

import DeleteProduct from "./DeleteProduct";
import AboutUpdate from "../updates/AboutUpdate";
import EligUpdate from "../updates/EligUpdate";
import FaqsUpdate from "../updates/FaqsUpdate";
import Loader from "../../../components/loader/Loader";
import ProductUpdate from "../updates/ProductUpdate";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function SingleProduct() {
  const { id } = useParams();

  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [change, setChange] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);

  // fetch document data
  useEffect(() => {
    setLoading(true);
    getSingleProduct(id)
      .then((doc) => {
        setLoading(false);
        setProduct(doc.data());
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [id, change]);

  if (!loading) {
    return (
      <Layout>
        <div className="flex justify-between items-center">
          <h3>
            {" "}
            <span className="font-normal"> Products |</span> {product.name}
          </h3>

          <div>
            {/* <button className="btn-primary text-sm font-normal">Edit</button> */}
          </div>
        </div>
        <Border />

        {/* Show Product Details */}
        <div className="p-10 border  rounded-lg border-gray-400 flex justify-between gap-20">
          <div className="flex-1">
            {" "}
            <span className="pill my-2">Product Name</span>
            <h3 className="prod-header mt-3 mb-4">{product.name}</h3>
            <span className="pill ">Short Description</span>
            <h3 className="text-base mt-2 font-normal">{product.shortDesc}</h3>
          </div>
          <div>
            {/* <button className="btn-primary text-sm font-normal">Edit</button> */}
            <button
              onClick={() => setDeleteModal(true)}
              className="btn-primary text-sm font-normal ml-5"
            >
              Delete
            </button>
          </div>
        </div>
        <div className="flex justify-between gap-10 w-full mt-10">
          {/* ABOUT */}
          <div className="my-5 flex-1">
            <h3 className="text-2xl font-bold text-primary flex items-center">
              About
              <AboutUpdate
                change={change}
                setChange={setChange}
                product={product}
              />{" "}
            </h3>

            <p className="mt-5 font-normal text-base text-primary">
              {product.desc}
            </p>
          </div>

          {/* Elibibility */}
          <div className="flex-1">
            {product.eligibility && (
              <div className="my-5">
                <h3 className="text-2xl font-bold text-primary">
                  Eligibility / Requirement{" "}
                  <EligUpdate
                    change={change}
                    setChange={setChange}
                    product={product}
                  />{" "}
                </h3>

                <div className="mt-5">
                  {product.eligibility.map((single) => {
                    return (
                      <p
                        id={single.id}
                        className="my-2 font-normal text-base text-primary"
                      >
                        {" "}
                        <VscDebugBreakpointConditional className="inline mr-2" />
                        {single.name}
                      </p>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>

        <Border />
        <div className="my-5 flex-1">
          <h3 className="text-2xl font-bold text-primary flex items-center">
            Partners
            <ProductUpdate
              change={change}
              setChange={setChange}
              product={product}
            />{" "}
          </h3>

          <div className="grid gap-5 grid-cols-4 my-10">
            {product.partners.map((single) => {
              return (
                <div>
                  <img src={single.url} alt="partner" />
                </div>
              );
            })}
          </div>
        </div>
        <Border />

        {/* FAQS */}
        {product.faqs && (
          <div className="my-10">
            <h3 className="text-2xl font-bold text-primary">
              FAQs{" "}
              <FaqsUpdate
                change={change}
                setChange={setChange}
                product={product}
              />{" "}
            </h3>
            <div className="my-10">
              {product.faqs.map((single) => {
                return (
                  <Disclosure
                    as="div"
                    defaultOpen={single.position === 1}
                    key={single.question}
                    className=""
                  >
                    {({ open }) => (
                      <>
                        <dt className="text-lg my-6 rounded-lg pb-2 text-primary">
                          <Disclosure.Button className="flex w-full items-start justify-between text-left">
                            <span className="text-primary font-semibold">
                              {single.question}
                            </span>
                            <hr />
                            <span className="flex h-7 w-7 items-center">
                              <BsArrowDownCircle
                                className={classNames(
                                  open ? "-rotate-180" : "rotate-0",
                                  "h-6 w-6 transform"
                                )}
                                aria-hidden="true"
                              />
                            </span>
                          </Disclosure.Button>
                        </dt>
                        <Disclosure.Panel as="dd" className="mt-1">
                          <p className="text-base font-normal text-primary max-w-2xl">
                            {single.answer}
                          </p>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                );
              })}
            </div>
          </div>
        )}

        {/* Delete Modal */}
        <Modal
          title={`Delete ${product.name} ?`}
          isOpen={deleteModal}
          setIsOpen={setDeleteModal}
        >
          <DeleteProduct setDeleteModal={setDeleteModal} product={product} />
        </Modal>
      </Layout>
    );
  } else {
    return (
      <Layout>
        <div className="flex justify-between items-center">
          <h3>
            {" "}
            <span className="font-normal"> Product </span>
          </h3>

          <div>
            <button className="btn-primary text-sm font-normal">Edit</button>
          </div>
        </div>

        <Loader />
      </Layout>
    );
  }
}

export default SingleProduct;
