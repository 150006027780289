import React from "react";

import Navbar from "../../../components/navbar/Navbar";
import Footer from "../../../components/footer/Footer";

import { BsArrowRight } from "react-icons/bs";

import CTA from "../../../components/cta/CTA";

function ProductLoading() {
  return (
    <div>
      <div className="h-[27rem] bg-primary">
        <div className="pt-7">
          <Navbar />
        </div>

        <div className="header text-white w-96 mx-auto  mt-20 text-center">
          <div className="mx-auto skeleton skeleton-text"></div>

          <div className=" mx-auto skeleton skeleton-text"></div>

          <button className="btn-secondary mt-5">
            Get Started <BsArrowRight className="inline ml-4" />
          </button>
        </div>
      </div>

      <div className="w-full mx-5 md:max-w-6xl  md:mx-auto my-20">
        <h3 className="text-2xl font-bold text-primary mb-5">About</h3>
        <p>
          <div className="skeleton skeleton-text"></div>
          <div className="skeleton skeleton-text"></div>
          <div className="skeleton skeleton-text"></div>
          <div className="skeleton skeleton-text"></div>
          <div className="skeleton skeleton-text"></div>
        </p>
        <button className="btn-secondary mt-5">
          Get Started <BsArrowRight className="inline ml-4" />
        </button>

        {/* Eligibility, partners and terms */}

        <div className="flex flex-col md:flex-row flex-between gap-10 mt-20">
          <div className="product-bubble flex-1">
            <h3 className="text-2xl font-bold text-primary mb-5">
              Eligibility / Requirement
            </h3>
            <div className="skeleton skeleton-text"> </div>
            <div className=" skeleton skeleton-text"> </div>
            <div className="skeleton skeleton-text"> </div>
            <div className=" skeleton skeleton-text"> </div>
          </div>
          <div className="product-bubble flex-1">
            <h3 className="text-2xl font-bold text-primary mb-5">Partners</h3>
            <div className="skeleton skeleton-text"> </div>
            <div className=" skeleton skeleton-text"> </div>{" "}
            <div className="skeleton skeleton-text"> </div>
            <div className=" skeleton skeleton-text"> </div>
          </div>
        </div>

        <div className="mt-10 mb-20 product-bubble">
          <h3 className="text-2xl font-bold text-primary mb-5">
            Terms & Conditions
          </h3>
          <div className="skeleton skeleton-text"> </div>
          <div className=" skeleton skeleton-text"> </div>{" "}
          <div className="skeleton skeleton-text"> </div>
          <div className=" skeleton skeleton-text"> </div>
        </div>
      </div>

      <div className="mt-20 p-20 bg-[#F8F7FC]">
        {" "}
        <div className="w-full mx-5 md:max-w-3xl  md:mx-auto">
          <h3 className="text-2xl font-bold text-primary mb-10">FAQs</h3>
          <div className=" skeleton skeleton-text"> </div>{" "}
          <div className="skeleton skeleton-text"> </div>
          <div className=" skeleton skeleton-text"> </div>
          <div className=" skeleton skeleton-text"> </div>{" "}
          <div className="skeleton skeleton-text"> </div>
          <div className=" skeleton skeleton-text"> </div>
          <div className=" skeleton skeleton-text"> </div>{" "}
          <div className="skeleton skeleton-text"> </div>
          <div className=" skeleton skeleton-text"> </div>
        </div>
      </div>
      <CTA />
      <Footer />
    </div>
  );
}

export default ProductLoading;
