import React, { useState } from "react";

import { deleteUser } from "../../../firebase";

import Notify from "../../../components/notifications/Notify";

function Delete({ selectedUser, showDel, setShowDel, setChange, change }) {
  const [loading, setLoading] = useState(false);

  const deleteUserFunction = () => {
    setLoading(true);
    Notify("info", "Deleting, please wait");

    deleteUser(selectedUser.id)
      .then(() => {
        setShowDel(false);
        setLoading(false);
        Notify("success", "Deleted successfully");
      })
      .catch((e) => {
        setShowDel(false);
        setLoading(false);
        Notify("error", "Error occured");
      });
  };

  return (
    <div className="text-center">
      <p className="font-normal mx-auto text-lg w-96 mb-10">
        This action is irreversible and the data cannot be recovered ever
      </p>

      <button
        disabled={loading}
        onClick={() => deleteUserFunction()}
        className="btn-danger"
      >
        I understand, delete {selectedUser.name}
      </button>
    </div>
  );
}

export default Delete;
