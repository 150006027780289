import React, { useRef } from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import Facts from "./components/Facts";
import ProductsPreview from "./components/ProductsPreview";
import Partners from "./components/Partners";
import CTA from "../../components/cta/CTA";

// info@vanetworks.net
// Vatnetwork

function Landing() {
  // scroll to top
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const partnersSection = useRef(null);

  return (
    <div>
      <div className="bg-hero bg-center bg-cover min-h-screen w-full pt-7">
        <Navbar partnersSection={partnersSection} />

        {/* Content */}
        <div className="flex items-center justify-center text-white">
          <div>
            <div className="w-full md:px-0 md:max-w-4xl relative text-center">
              <h1 className="px-5 md:px-0 mt-96 font-bold text-5xl md:text-7xl">
                Building, Protecting & Sustaining your Wealth
              </h1>
              <p className="font-light text-xl mt-5 max-w-xl mx-auto">
                We offer premium digital financial solutions that meet your
                aspirations at every stage of your life
              </p>
            </div>
          </div>
        </div>
      </div>

      <Facts />
      <ProductsPreview />

      <div ref={partnersSection}>
        <Partners />
      </div>

      <CTA />

      <Footer />
    </div>
  );
}

export default Landing;
