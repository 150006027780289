import React from "react";

import { BsDownload } from "react-icons/bs";

import { getStorage, ref, getDownloadURL } from "firebase/storage";

// import axios from "axios";
// import fileDownload from "js-file-download";

function Terms({ product }) {
  let details = product.terms;

  const handleDownload = (url, filename) => {
    const storage = getStorage();

    getDownloadURL(
      ref(
        storage,
        `products/${product.name.toLowerCase()}/terms/${details.name}`
      )
    )
      .then((url) => {
        // `url` is the download URL for 'images/stars.jpg'

        // This can be downloaded directly:
        const xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = (event) => {
          const blob = xhr.response;
        };
        xhr.open("GET", url);
        xhr.send();
      })
      .catch((error) => {
        // Handle any errors
      });

    // axios
    //   .get(url, {
    //     responseType: "blob",
    //   })
    //   .then((res) => {
    //     fileDownload(res.data, filename);
    //   });
  };

  return (
    <div>
      <h3 className="text-2xl font-bold text-primary">Terms & Conditions</h3>
      <div className="my-5">
        <p className="max-w-xl text-left text-primary">{details.termsDesc}</p>
      </div>
      {/* <button
        onClick={() =>
          handleDownload(details.url, product.name + " terms and conditions")
        }
        className="btn-primary"
      >
        <BsDownload className="inline-block mr-3" /> Download
      </button> */}

      <a href={details.url} download>
        {" "}
        <button className="btn-primary">
          {" "}
          <BsDownload className="inline-block mr-3" /> Download
        </button>
      </a>
    </div>
  );
}

export default Terms;
