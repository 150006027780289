import React from "react";

import { Tab } from "@headlessui/react";

//sections
import Resolved from "./Resolved";
import Open from "./Open";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function AllClients() {
  return (
    <div>
      <div className="w-full">
        <Tab.Group>
          <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
            <Tab
              key="resolved"
              className={({ selected }) =>
                classNames(
                  "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700",
                  "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                  selected
                    ? "bg-white shadow"
                    : "text-blue-500 hover:bg-white/[0.12] hover:text-white"
                )
              }
            >
              New Requests
            </Tab>
            <Tab
              key="resolved"
              className={({ selected }) =>
                classNames(
                  "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700",
                  "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                  selected
                    ? "bg-white shadow"
                    : "text-blue-500 hover:bg-white/[0.12] hover:text-white"
                )
              }
            >
              Resolved
            </Tab>
          </Tab.List>
          <Tab.Panels className="mt-5">
            {/* Unresolved / NEW */}
            <Tab.Panel>
              <Open />
            </Tab.Panel>

            {/* RESOVLEED */}
            <Tab.Panel>
              <Resolved />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
}

export default AllClients;
